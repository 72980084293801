import { useState, Fragment, useEffect } from "react";
import "./sidebar.css";
import "./Aside/nav.css"
import { useLocation } from "react-router";
import Header from './components/header';
import Footer from './components/footer';
import Routing from "./router";
import { useMediaQuery } from "react-responsive";
import Aside from "../src/Aside";


function App() {
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };
  const handleToggle = () => {
    setToggled(prev => !prev);
  };

  const isTabletOrMobile = useMediaQuery({ maxWidth: 768 });
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);


  // const [isDarkMode, setIsDarkMode] = useState(() => false);

  return (

    <Fragment>

        {isTabletOrMobile ? (
          <Aside
            collapsed={collapsed}
            toggled={toggled}
            handleToggleSidebar={handleToggleSidebar}
            handleToggle={handleToggle}
          />
        ) : (
          ""
        )}
      <Header isMobile={isTabletOrMobile}
          toggled={toggled}
          handleToggleSidebar={handleToggleSidebar} />
        <Routing />
      <Footer />
    </Fragment>
  );
}

export default App;

