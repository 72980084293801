import { React, useState, useEffect, useRef } from 'react';
import forwardarrow from '../img/forward-arrow.svg';
import '../ReadMoreButton/index';
import universitylogo from '../img/university-logo.png';
import loanimg from '../img/loan-img.svg';
import testimg from '../img/test-img.svg';
import rec1 from '../img/rec1.jpg';
import dimg from '../img/d-img.jpg';
import Form from 'react-bootstrap/Form';
import { Triangle } from 'react-loader-spinner'
import galleriestab from '../img/galleries-tab.avif';
import "../../../node_modules/slick-carousel/slick/slick.css"
import "../../../node_modules/slick-carousel/slick/slick-theme.css"
import Slider from "react-slick";
import { Link, useParams, Redirect } from 'react-router-dom';
import Map from '../../components/map.svg'
import { Button, Spinner } from 'react-bootstrap';
import lister1 from '../img/lister1.jpg';
import iconlister from '../img/iconlister.jpg';
import { IoIosPhonePortrait } from "react-icons/io";
import { MdOutlineAttachEmail } from "react-icons/md";
import ReactReadMoreReadLess from 'react-read-more-read-less';
import { Helmet } from "react-helmet";
import RussiaMap from '../../img/russia_map.svg';
import { LuUserCircle } from "react-icons/lu";
import Carousel from 'react-gallery-carousel';
import '../../../node_modules/react-gallery-carousel/dist/index.css';
import Swal from 'sweetalert2';
import PhoneInput from "react-phone-input-2";
import Images from "../../img/calendar.svg";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { GrView } from "react-icons/gr";
const getCookie = (name) => {
    let value = `; ${document.cookie}`;
    let parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}
const cache = {
    blogdetails: null,
};




const University = () => {
    const { uid } = useParams();
    const [univerSity, setUniverSity] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [UniversityInfo, setUniversityInfo] = useState(null);
    const [ranking, setRanking] = useState(null);
    const [courses, setCourses] = useState(null);
    const [reviews, setReviews] = useState(null);
    const [faculty, setFaculty] = useState(null);
    const [hostel, setHostel] = useState(null);
    const [Gallery, setGallery] = useState(null);
    const [colleges, setColleges] = useState(null);
    const [news, setNews] = useState(null);
    const [qandA, setQandA] = useState(null);
    const [FAQ, setFAQ] = useState(null);
    const [AdmissionandEligibility, setAdmissionandEligibility] = useState(null);
    const [Campus, setCampus] = useState(null)
    const [notFound, setNotFound] = useState(false);
    const [blogdetails, setBlogdetails] = useState([]);
    const formRef = useRef(null);
    const [phoneNumberr, setPhoneNumberr] = useState(''); // State for phone input
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    // free read more 

    const submitForm = async (event) => {
        event.preventDefault();

        const formData = new FormData(event.target);
        formData.append('current_url', getCookie("url"));
        formData.append('campaign', 'mbbs-in-russia-from-main-page');

        try {
            setLoading(true);

            // Convert FormData to a plain object
            const data = {};
            formData.forEach((value, key) => {
                data[key] = value;
            });

            const response = await fetch('https://webcrmapi.nayeedisha.in/api/landingpage-leads', {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const responseData = await response.json();

            if (!response.ok) {
                // Check if responseData.error is an object and handle it accordingly
                let errorText = 'Failed to submit form';
                if (responseData.error) {
                    if (typeof responseData.error === 'object') {
                        errorText = Object.values(responseData.error).flat().join(', ');
                    } else {
                        errorText = responseData.error;
                    }
                }
                throw new Error(errorText);
            }

            setLoading(false);
            setSuccessMessage(responseData.message);
            setErrorMessage(null);

            formRef.current.reset(); // Reset the form

            Swal.fire({
                title: "Success!",
                text: responseData.message,
                icon: "success",
            }).then(() => {
                if (window.gtag) {
                    console.log("Logging Google Analytics event...");
                    window.gtag('event', 'submit', {
                        'event_category': 'Form',
                        'event_label': 'Admission Form',
                        'value': 'text_success'
                    });
                    console.log("Google Analytics event submitted.");
                } else {
                    console.warn("gtag function is not available.");
                }

                setTimeout(() => {
                    setSuccessMessage(null);
                }, 5000);
                setPhoneNumberr(''); // Clear phone input
                formRef.current.reset(); // Reset the entire form
            });
        } catch (error) {
            console.error("Error during form submission:", error);
            setLoading(false);
            setSuccessMessage(null);

            const errorText = error.message || 'Failed to submit form';

            setErrorMessage(errorText);

            Swal.fire({
                title: "Error!",
                text: errorText,
                icon: "error",
                showConfirmButton: false,
                timer: 1500
            });
        }
    };



    useEffect(() => {
        const fetchBlogDetails = async () => {
            setIsLoading(true);
            // Check if data is available in localStorage
            const cachedData = localStorage.getItem('blogdetails');

            if (cachedData) {
                setBlogdetails(JSON.parse(cachedData)); // Use cached data
                setIsLoading(false);
            } else {
                try {
                    const response = await fetch('https://webcrmapi.nayeedisha.in/api/blog-details');
                    const data = await response.json();
                    localStorage.setItem('blogdetails', JSON.stringify(data)); // Store fetched data in localStorage
                    setBlogdetails(data);
                } catch (error) {
                    console.error('Error fetching blog details:', error);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        fetchBlogDetails();
    }, []);

    useEffect(() => {
        const fetchUniversityDetails = async () => {
            setIsLoading(true);
            // Check if data is available in localStorage
            const cachedData = localStorage.getItem(`university-${uid}`);

            if (cachedData) {
                const data = JSON.parse(cachedData); // Use cached data
                populateUniversityData(data);
                setIsLoading(false);
            } else {
                try {
                    const response = await fetch(`https://webcrmapi.nayeedisha.in/api/university-details/${uid}`);
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const data = await response.json();
                    localStorage.setItem(`university-${uid}`, JSON.stringify(data)); // Store fetched data in localStorage
                    populateUniversityData(data);
                } catch (error) {
                    setNotFound(true);
                    console.error('Error fetching university details:', error);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        const populateUniversityData = (data) => {
            if (data.UniversityInfo) {
                const info = Array.isArray(data.UniversityInfo) ? data.UniversityInfo[0] : data.UniversityInfo;
                setUniversityInfo(info);
                setCourses(data.Courses);
                setRanking(data.Ranking);
                setReviews(data.Reviews);
                setFaculty(data.Faculty);
                setHostel(data.Hostel);
                setGallery(data.Gallery || []);
                setColleges(data.Colleges);
                setNews(data.News);
                setFAQ(data.FAQ);
                setAdmissionandEligibility(data.AdmissionandEligibility);
                setCampus(data.Campus);
            } else {
                setNotFound(true);
            }
        };

        fetchUniversityDetails();
    }, [uid]);

    const images = (Gallery || [])
        .filter(data => data.image)  // Filter out entries without an image
        .map(data => ({
            src: data.image,
            alt: data.description || 'Gallery image',
        }));

    const getUgcapproved = (value) => {
        return value === 1 ? 'YES' : 'No';
    };

    // Word test limit
    const truncateWords = (text, limit) => {
        if (!text) return ''; // Check if text is not provided
        const words = text.split(' ');
        return words.slice(0, limit).join(' ');
    };

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 3,
        arrows: true,
        className: "myCustomCarousel"
    };


    if (isLoading) {
        return (
            <div className='container mt-3 mb-3'>
                <Skeleton className='mt-3' count={4} />
                <Skeleton className='mt-3' count={4} />
                <Skeleton className='mt-3' count={4} />
                <Skeleton className='mt-3' count={4} />
            </div>
        );
    }

    if (notFound) {
        return <Redirect to="/404" />;
    }

    return (
        <>

            <div className="application">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{UniversityInfo?.metatitle || 'Loading..'}</title>
                    <meta name="description" content={UniversityInfo?.metadescription || 'Loading..'} />
                    <meta name="keywords"
                        content={UniversityInfo?.metakeywords || 'Loading..'} />
                    <link rel="canonical" href="https://nayeedisha.in" />
                </Helmet>

            </div>
            {/* section1 */}

            {isLoading ? (
                <div className='d-flex justify-content-center py-5'>
                    <Skeleton count={5} />
                </div>
            )


                : (

                    UniversityInfo && (
                        <div className='university-section pt-5 pb-5' key={UniversityInfo.uid} style={{ width: '100%', backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${UniversityInfo.cover_image})`, backgroundRepeat: 'no-repeat', backgroundSize: '100% 151%', }}>
                            <div className='container'>
                                <div className='first-section d-flex'>
                                    <div className='second-section'>
                                        <img src={UniversityInfo.logo} alt="Nayeedisha" style={{ width: "" }} />
                                    </div>&nbsp;&nbsp;
                                    <div className='third-section pt-2'>
                                        <p> {UniversityInfo.name ? UniversityInfo.name : 'Null'}</p>
                                        <div className='mysvgs mt-2'>
                                            <img src={Map} alt='Nayeedisha' className='map_img' />
                                            <span className='para1' style={{ color: '#fff', fontWeight: 700, fontSize: 17 }}>Year Of Establishment :<span className='ugc'> {UniversityInfo.estb ? UniversityInfo.estb : 'NULL'} </span></span>&nbsp;&nbsp;&nbsp;&nbsp;

                                        </div>
                                    </div>


                                </div>




                            </div>

                            <div className='container'>
                                <div className='col-md-5 mt-4 offset-md-7 '>
                                    <div className="banner-copy-form-universs p-4 nav-ani" >
                                        <h5 className='aniafter anibanner'> &nbsp; &nbsp; Get Free Counselling</h5>
                                        <form ref={formRef} className="align" onSubmit={submitForm} >
                                            <div className="mb-3">
                                                <label className="form-label">
                                                    Full Name <span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    className="form-control"
                                                    placeholder="Full Name" required
                                                />

                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">
                                                    Phone <span className="text-danger">*</span>
                                                </label>
                                                <PhoneInput inputProps={{
                                                    name: 'phone_number', required: true, autoFocus: true
                                                }}
                                                    country={'in'}
                                                    value={phoneNumberr}
                                                    onChange={(phone) => setPhoneNumberr(phone)}

                                                />
                                                {/* <PhoneInput inputProps={{ name: 'phone_number', required: true, autoFocus: false }} country={'in'}  value={phoneNumber} onChange={(phone) => setPhoneNumber(phone)} /> */}
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">
                                                    Select State <span className="text-danger">*</span>
                                                </label>
                                                <select
                                                    name="state"
                                                    className="form-control"

                                                >
                                                    <option value="">Please Select Preferred State</option>
                                                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                                                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                                    <option value="Assam">Assam</option>
                                                    <option value="Bihar">Bihar</option>
                                                    <option value="Chhattisgarh">Chhattisgarh</option>
                                                    <option value="Gujarat">Gujarat</option>
                                                    <option value="Haryana">Haryana</option>
                                                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                                                    <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                                                    <option value="Goa">Goa</option>
                                                    <option value="Jharkhand">Jharkhand</option>
                                                    <option value="Karnataka">Karnataka</option>
                                                    <option value="Kerala">Kerala</option>
                                                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                                                    <option value="Maharashtra">Maharashtra</option>
                                                    <option value="Manipur">Manipur</option>
                                                    <option value="Meghalaya">Meghalaya</option>
                                                    <option value="Mizoram">Mizoram</option>
                                                    <option value="Nagaland">Nagaland</option>
                                                    <option value="Odisha">Odisha</option>
                                                    <option value="Punjab">Punjab</option>
                                                    <option value="Rajasthan">Rajasthan</option>
                                                    <option value="Sikkim">Sikkim</option>
                                                    <option value="Tamil Nadu">Tamil Nadu</option>
                                                    <option value="Telangana">Telangana</option>
                                                    <option value="Tripura">Tripura</option>
                                                    <option value="Uttarakhand">Uttarakhand</option>
                                                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                                                    <option value="West Bengal">West Bengal</option>
                                                    <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                                                    <option value="Chandigarh">Chandigarh</option>
                                                    <option value="Dadra and Nagar Haveli">Dadra and Nagar Haveli</option>
                                                    <option value="Daman and Diu">Daman and Diu</option>
                                                    <option value="Delhi">Delhi</option>
                                                    <option value="Lakshadweep">Lakshadweep</option>
                                                    <option value="Puducherry">Puducherry</option>
                                                </select>

                                            </div>
                                            <div className="cp-but mt-4">
                                                <button type="submit" className="btn btn-primary">Submit</button>
                                            </div>
                                            {successMessage && <p className="text-success">{successMessage}</p>}
                                            {errorMessage && <p className="text-danger">{errorMessage}</p>}
                                        </form>
                                    </div>
                                    <div className='icon-btn d-flex justify-content-end'>

                                        <button type="button" class="btn btn-primary mobilehide mt-2">
                                            <div className='apply-content' data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo" id='btnr1'>

                                                <div className='d-flex justify-content-between '>

                                                    Apply Now


                                                    <div >  <img src={forwardarrow} class="" alt="Nayeedisha" /></div>
                                                </div>
                                            </div>
                                        </button>&nbsp;&nbsp;




                                        {UniversityInfo.brochure ?

                                            <button type="button" class="btn mt-2 d-none" id="btnrs2">
                                                <a href={UniversityInfo.brochure} download target="_blank">
                                                    <div className='d-flex justify-content-between '>
                                                        <div> Download Brochure </div>
                                                        <div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                <path d="M2.66675 10.6693V11.3333C2.66675 11.8638 2.87746 12.3725 3.25253 12.7475C3.62761 13.1226 4.13632 13.3333 4.66675 13.3333H11.3334C11.8638 13.3333 12.3726 13.1226 12.7476 12.7475C13.1227 12.3725 13.3334 11.8638 13.3334 11.3333V10.6667M8.00008 3V10.3333M8.00008 10.3333L10.3334 8M8.00008 10.3333L5.66675 8" stroke="#2D69F0" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </a>
                                            </button>
                                            : null
                                        }

                                    </div>
                                </div>
                            </div>


                        </div>

                    )
                )}

            {isLoading ? (
                <Skeleton count={5} />
            ) : (
                UniversityInfo && (
                    <div className='container p-0 page-borderd'>
                        <div className='row'>
                            <div className='col-lg-8 col-md-8'>
                                <nav className='nav-ani' style={{ zIndex: 999 }}>
                                    <div class="nav nav-tabs  mobile-navflex" id="nav-tab" role="tablist" >

                                        <div class="nav-link active" id="nav-overview-tab" data-bs-toggle="tab" data-bs-target="#nav-overview" type="button" role="tab" aria-controls="nav-overview" aria-selected="true"><b> Introduction </b></div>&nbsp;&nbsp;
                                        <div class="nav-link" id="nav-courses-tab" data-bs-toggle="tab" data-bs-target="#nav-courses" type="" role="tab" aria-controls="nav-courses" aria-selected="false"><b className='button-width'>Courses & Fees</b></div>&nbsp;&nbsp;
                                        <button class="nav-link" id="nav-program-tab" data-bs-toggle="tab" data-bs-target="#nav-program" type="button" role="tab" aria-controls="nav-program" aria-selected="false"><b className='button-width'>Admission & Eligibility</b></button>&nbsp;&nbsp;
                                        <button class="nav-link" id="nav-admission-tab" data-bs-toggle="tab" data-bs-target="#nav-admission" type="button" role="tab" aria-controls="nav-admission" aria-selected="false"><b className='button-width'>Benefits</b></button>&nbsp;&nbsp;
                                        <button class="nav-link" id="nav-scholarship-tab" data-bs-toggle="tab" data-bs-target="#nav-scholarship" type="button" role="tab" aria-controls="nav-scholarship" aria-selected="false"><b className='button-width'>Campus Info</b></button>&nbsp;&nbsp;
                                        {/* <button class="nav-link" id="nav-placement-tab" data-bs-toggle="tab" data-bs-target="#nav-placement" type="button" role="tab" aria-controls="nav-placement" aria-selected="false">Ranking</button>&nbsp;&nbsp; */}
                                        <button class="nav-link" id="nav-reviews-tab" data-bs-toggle="tab" data-bs-target="#nav-reviews" type="button" role="tab" aria-controls="nav-reviews" aria-selected="false"><b>Reviews</b></button>&nbsp;&nbsp;



                                    </div>
                                </nav>
                                <div class="tab-content" id="nav-tabContent">
                                    <div class="tab-pane fade show active" id="nav-overview" role="tabpanel" aria-labelledby="nav-overview-tab">


                                        <div className='container mt-3'>

                                            <div className="card">
                                                <div className="card-body overview">
                                                    <h2 data-toggle="tooltip" data-placement="top" title="Tooltip on top">Introduction About {UniversityInfo.name ? UniversityInfo.name : 'Null'}</h2>
                                                    <div className='overview-para'>
                                                        <div dangerouslySetInnerHTML={{ __html: UniversityInfo.aboutuniversity || '' }} />
                                                    </div>
                                                </div>





                                            </div>
                                        </div>

                                        <div className='container divider-crd'>
                                            <div class="card mt-5 mb-5">
                                                <div class="card-body">



                                                    <h5><b>At Glance: </b></h5>

                                                    {isLoading ? (
                                                        <div className='text-center'>
                                                            <Spinner animation="border" role="status">
                                                                <span className="visually-hidden">Loading...</span>
                                                            </Spinner>
                                                        </div>
                                                    ) : (

                                                        <div className='row'>

                                                            {courses.map((data) => {
                                                                return (

                                                                    <div>

                                                                        {/* <p>{data.about_course ? data.about_course : 'Null'}</p> */}

                                                                        <div className='col-lg-12 col-md-12 d-flex justify-content-between pt-1'>

                                                                            <table class="table table-bordered">
                                                                                <thead>
                                                                                    <tr>

                                                                                        <th scope="col">Intake for {data.name ? data.name : 'Null'} </th>
                                                                                        <td scope="col">{data.intake ? data.intake : 'Null'}</td>

                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>

                                                                                        <th>Minimum percentage for {data.name ? data.name : 'Null'} </th>
                                                                                        <td>{UniversityInfo.minimum_percentage_for_mbbs ? UniversityInfo.minimum_percentage_for_mbbs : '50'}% in PCB</td>

                                                                                    </tr>
                                                                                    <tr>

                                                                                        <th>NEET Requirement</th>
                                                                                        <td>{UniversityInfo.neet_requirement ? 'Yes' : 'No'}</td>
                                                                                    </tr>

                                                                                    <tr>

                                                                                        <th>Recognition of {UniversityInfo.name ? UniversityInfo.name : 'Null'}</th>
                                                                                        <td>{UniversityInfo.recognition ? UniversityInfo.recognition : 'Null'}</td>

                                                                                    </tr>
                                                                                    <tr><th>Nearby Airport to {UniversityInfo.name ? UniversityInfo.name : 'Null'}</th><td>{UniversityInfo.nearby_airport ? UniversityInfo.nearby_airport : 'Null'}</td>

                                                                                    </tr>

                                                                                </tbody>
                                                                            </table>
                                                                        </div>


                                                                    </div>

                                                                )
                                                            })
                                                            }
                                                            <hr></hr>

                                                            {ranking.map((data) => {
                                                                return (

                                                                    <div>

                                                                        {/* <p>{data.about_course ? data.about_course : 'Null'}</p> */}

                                                                        <div className='col-lg-12 col-md-12 d-flex justify-content-between pt-1'>

                                                                            <table class="table table-bordered">
                                                                                <thead>
                                                                                    <tr>

                                                                                        <th scope="col">Word Ranking {data.authority ? data.authority : 'Null'} </th>
                                                                                        <td scope="col">{data.authority ? data.authority : 'Null'}</td>
                                                                                        <th scope="col">Ranking {data.ranking ? data.ranking : 'Null'} </th>
                                                                                        <td scope="col">{data.ranking ? data.ranking : 'Null'}</td>

                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>

                                                                                        <th>Ranking Year {data.rankingyear ? data.rankingyear : 'Null'} </th>
                                                                                        <td>{UniversityInfo.rankingyear ? UniversityInfo.rankingyear : '50'}</td>

                                                                                    </tr>

                                                                                </tbody>
                                                                            </table>
                                                                        </div>


                                                                    </div>

                                                                )
                                                            })
                                                            }
                                                        </div>
                                                    )}








                                                </div>
                                            </div>
                                        </div>

                                        <div className='container divider-crd'>
                                            <div class="card mt-5 mb-5">
                                                <div class="card-body">



                                                    <h5>Key Features of {UniversityInfo.name}</h5>

                                                    {isLoading ? (
                                                        <div className='text-center'>
                                                            <Spinner animation="border" role="status">
                                                                <span className="visually-hidden">Loading...</span>
                                                            </Spinner>
                                                        </div>
                                                    ) : (

                                                        <div className='row'>



                                                            <div>
                                                                {/* <p>{UniversityInfo.facts ? UniversityInfo.facts : 'Null'}</p> */}

                                                                <div dangerouslySetInnerHTML={{ __html: UniversityInfo.facts || '' }} />



                                                            </div>



                                                        </div>
                                                    )}








                                                </div>
                                            </div>
                                        </div>



                                        {/*  */}
                                        <section id=''>
                                            <div className='container divider-crd'>
                                                <div class="card mt-5 mb-5">
                                                    <div class="card-body">
                                                        <h5>Courses & Fees</h5>

                                                        {isLoading ? (
                                                            <div className='text-center'>
                                                                <Spinner animation="border" role="status">
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </Spinner>
                                                            </div>
                                                        ) : (

                                                            <div className='row'>



                                                                {courses.map((data) => {
                                                                    return (
                                                                        <div>
                                                                            <p><b>About the course</b> :
                                                                                {/* {data.about_course ? data.about_course : 'Null'} */}
                                                                                <div dangerouslySetInnerHTML={{ __html: data.about_course || '' }} />

                                                                            </p>

                                                                            {isLoading ? (
                                                                                <div className='text-center'>
                                                                                    <Spinner animation="border" role="status">
                                                                                        <span className="visually-hidden">Loading...</span>
                                                                                    </Spinner>
                                                                                </div>
                                                                            ) : (

                                                                                <div className='row'>



                                                                                    {courses.map((data) => {
                                                                                        return (
                                                                                            <div>

                                                                                                <hr></hr>
                                                                                                <h5 className='mt-3'>Course Facts:</h5>
                                                                                                <div className='col-lg-12 col-md-12 d-flex justify-content-between pt-1'>


                                                                                                    <table class="table table-bordered">
                                                                                                        <thead>
                                                                                                            {data.name ?
                                                                                                                <tr>

                                                                                                                    <th scope="col">Course Name</th>
                                                                                                                    <td scope="col">{data.name ? data.name : 'Null'}</td>

                                                                                                                </tr>
                                                                                                                : null}

                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                            {data.duration ?
                                                                                                                <tr>

                                                                                                                    <th>Duration</th>
                                                                                                                    <td>{data.duration ? data.duration : 'Null'}</td>

                                                                                                                </tr>
                                                                                                                : null
                                                                                                            }
                                                                                                            {data.teacher_student_ratio ?
                                                                                                                <tr>

                                                                                                                    <th>Teacher Student Ratio</th>
                                                                                                                    <td>{data.teacher_student_ratio ? data.teacher_student_ratio : 'Null'}</td>

                                                                                                                </tr>
                                                                                                                : null
                                                                                                            }
                                                                                                            {data.total_budget ?
                                                                                                                <tr>
                                                                                                                    <th>Total Budget</th>
                                                                                                                    <td>{data.total_budget ? data.total_budget : 'Null'}   </td>
                                                                                                                </tr>
                                                                                                                : null
                                                                                                            }


                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </div>


                                                                                            </div>

                                                                                        )
                                                                                    })
                                                                                    }



                                                                                </div>


                                                                            )}

                                                                            <hr></hr>
                                                                            <h5 className='mt-3 fee_structure'>Fee Structure  </h5>
                                                                            <div className='col-lg-12 col-md-12 d-flex justify-content-between pt-1'>


                                                                                <table class="table table-bordered">

                                                                                    <tbody>

                                                                                        {data.totalfee ?

                                                                                            <tr>
                                                                                                <th>Total Fees</th>
                                                                                                <td>{data.totalfee ? data.totalfee : 'Null'} {data.currency ? data.currency : 'Null'}</td>
                                                                                            </tr>

                                                                                            : null
                                                                                        }

                                                                                        {data.first_year_fee ?
                                                                                            <tr>
                                                                                                <th>First Year Fee</th>
                                                                                                <td>{data.first_year_fee ? data.first_year_fee : 'Null'} {data.currency ? data.currency : 'Null'}</td>
                                                                                            </tr>

                                                                                            : null
                                                                                        }

                                                                                        {data.second_year_fee ?
                                                                                            <tr>
                                                                                                <th>Second Year Fee</th>
                                                                                                <td>{data.second_year_fee ? data.second_year_fee : 'Null'} {data.currency ? data.currency : 'Null'}</td>
                                                                                            </tr>
                                                                                            : null
                                                                                        }

                                                                                        {data.third_year_fee ?
                                                                                            <tr>
                                                                                                <th>Third Year Fee</th>
                                                                                                <td>{data.third_year_fee ? data.third_year_fee : 'Null'} {data.currency ? data.currency : 'Null'}</td>
                                                                                            </tr>
                                                                                            : null
                                                                                        }

                                                                                        {data.fourth_year_fee ?
                                                                                            <tr>
                                                                                                <th>Fourth Year Fee</th>
                                                                                                <td>{data.fourth_year_fee ? data.fourth_year_fee : 'Null'} {data.currency ? data.currency : 'Null'}</td>
                                                                                            </tr>
                                                                                            : null
                                                                                        }

                                                                                        {data.fifth_year_fee ?
                                                                                            <tr>
                                                                                                <th>Fifth Year Fee</th>
                                                                                                <td>{data.fifth_year_fee ? data.fifth_year_fee : 'Null'} {data.currency ? data.currency : 'Null'}</td>
                                                                                            </tr>
                                                                                            : null
                                                                                        }

                                                                                        {data.sixth_year_fee ?
                                                                                            <tr>
                                                                                                <th>Sixth Year Fee</th>
                                                                                                <td>{data.sixth_year_fee ? data.sixth_year_fee : 'Null'} {data.currency ? data.currency : 'Null'}</td>
                                                                                            </tr>
                                                                                            : null
                                                                                        }
                                                                                        {data.tuition_fees ?

                                                                                            <tr>

                                                                                                <th>Tuition Fees</th>
                                                                                                <td>{data.tuition_fees ? data.tuition_fees : 'Null'} {data.currency ? data.currency : 'Null'} </td>

                                                                                            </tr>
                                                                                            : null
                                                                                        }
                                                                                        {data.hostel_fees ?

                                                                                            <tr>

                                                                                                <th>Hostel Fees</th>
                                                                                                <td>{data.hostel_fees ? data.hostel_fees : 'Null'} {data.currency ? data.currency : 'Null'}</td>

                                                                                            </tr>
                                                                                            : null
                                                                                        }
                                                                                        {/* {data.food_cost ?

                                                                                            <tr>
                                                                                                <th>Food Cost</th>
                                                                                                <td>{data.food_cost} {data.currency ? data.currency : 'Null'}</td>
                                                                                            </tr>
                                                                                            : null
                                                                                        }


                                                                                        {data.living_cost ?
                                                                                            <tr>
                                                                                                <th>Living Cost</th>
                                                                                                <td>{data.living_cost} {data.currency ? data.currency : 'Null'}</td>
                                                                                            </tr>
                                                                                            : null
                                                                                        } */}

                                                                                    </tbody>

                                                                                </table>
                                                                            </div>


                                                                        </div>

                                                                    )
                                                                })
                                                                }



                                                            </div>


                                                        )}






                                                    </div>
                                                </div>
                                            </div>
                                        </section>



                                        <div className='container p-4 fee-section d-none '>
                                            <div className=''>



                                                <div className='row fee-section-2 p-4 '>
                                                    <div className='col-md-6 pt-3'>

                                                        <h3>Worried About College Fees?</h3>
                                                        <p>Convert your Upfront Fees in to Simple, Convenient & Affordable EMIs</p>

                                                        <button type="button" class="btn ">Apply For Education Loan</button>



                                                    </div>

                                                    <div className='col-md-6 text-center'>

                                                        <img src={loanimg} alt="Nayeedisha" style={{ width: "" }} />


                                                    </div>

                                                </div>
                                            </div>


                                        </div>

                                        <div className='container divider-crd'>
                                            <div class="card mt-5 mb-5">
                                                <div class="card-body">



                                                    {/* <h5>Admission & Eligibility</h5> */}

                                                    {isLoading ? (
                                                        <div className='text-center'>
                                                            <Spinner animation="border" role="status">
                                                                <span className="visually-hidden">Loading...</span>
                                                            </Spinner>
                                                        </div>
                                                    ) : (

                                                        <div className='row'>



                                                            {AdmissionandEligibility.map((data) => {
                                                                return (

                                                                    <div><br></br>

                                                                        <div className='col-lg-12 col-md-12 d-flex justify-content-between'>
                                                                            {/* <p>{data.eligibility_criteria}</p> */}

                                                                            <div dangerouslySetInnerHTML={{ __html: data.eligibility_criteria || '' }} />




                                                                        </div>
                                                                        <br></br>

                                                                        <div className='col-lg-12 col-md-12 d-flex justify-content-between pt-1'>

                                                                            <div dangerouslySetInnerHTML={{ __html: data.documents_required || '' }} />
                                                                        </div><br></br>
                                                                        {/* <b className=''>Why Choose Nayeedisha :-</b>
                                                                        <div className='col-lg-12 col-md-12 d-flex justify-content-between pt-4'>

                                                                            <p dangerouslySetInnerHTML={{ __html: data.why_choose_nayeedisha || '' }} />
                                                                        </div> */}



                                                                    </div>

                                                                )
                                                            })
                                                            }



                                                        </div>
                                                    )}








                                                </div>
                                            </div>
                                        </div>
                                        {/* advantage vs Disadvantage */}
                                        <div className='container divider-crd'>
                                            <div class="card mt-5 mb-5">
                                                <div class="card-body">



                                                    <h5>Why should you select {UniversityInfo.name} ?</h5>

                                                    {isLoading ? (
                                                        <div className='text-center'>
                                                            <Spinner animation="border" role="status">
                                                                <span className="visually-hidden">Loading...</span>
                                                            </Spinner>
                                                        </div>
                                                    ) : (

                                                        <div className='row'>


                                                            <div dangerouslySetInnerHTML={{ __html: UniversityInfo.advantage || '' }} />




                                                        </div>
                                                    )}








                                                </div>
                                            </div>
                                        </div>


                                        {/* close */}

                                        {/* campus */}
                                        <div className='container divider-crd'>
                                            <div class="card mt-5 mb-5">
                                                <div class="card-body">



                                                    <h5>Campus of {UniversityInfo.name}</h5>

                                                    {isLoading ? (
                                                        <div className='text-center'>
                                                            <Spinner animation="border" role="status">
                                                                <span className="visually-hidden">Loading...</span>
                                                            </Spinner>
                                                        </div>
                                                    ) : (


                                                        <div className='row'>



                                                            {Campus.map((data) => {
                                                                return (

                                                                    <div>
                                                                        <p>

                                                                            <div dangerouslySetInnerHTML={{ __html: data.about_campus || '' }} />

                                                                        </p>



                                                                        <p>

                                                                            <div dangerouslySetInnerHTML={{ __html: data.hostel || '' }} />

                                                                        </p>
                                                                        <p>

                                                                            <div dangerouslySetInnerHTML={{ __html: data.infrastructure || '' }} />

                                                                        </p>


                                                                        <p>

                                                                            <div dangerouslySetInnerHTML={{ __html: data.student_life || '' }} />

                                                                        </p>



                                                                        <p>

                                                                            <div dangerouslySetInnerHTML={{ __html: data.indian_food || '' }} />

                                                                        </p>

                                                                        <div className='col-lg-12 col-md-12 d-flex justify-content-between pt-1'>

                                                                            {/* <table class="table table-bordered">
                                                                        <thead>
                                                                            <tr>

                                                                                <th scope="col">Advantage</th>
                                                                                <th scope="col">Disadvantage</th>

                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>

                                                                                <td>{UniversityInfo.advantage ? UniversityInfo.advantage : 'Null'}</td>
                                                                                <td>{UniversityInfo.disadvantage ? UniversityInfo.disadvantage : 'Null'}</td>

                                                                            </tr>




                                                                        </tbody>
                                                                    </table> */}


                                                                        </div>


                                                                    </div>

                                                                )
                                                            })
                                                            }



                                                        </div>
                                                    )}








                                                </div>
                                            </div>
                                        </div>




                                        {/* section */}







                                        {/* section top courses*/}






                                        {/* related news section */}

                                        {/* <div className='container mt-5 news-section'>
                                    <h3 className='text-center'>Related News</h3>


                                    <div className='row'>



                                        {news.map((data) => {
                                            const formatDate = (dateString) => {
                                                const date = new Date(dateString);
                                                const day = date.getDate().toString().padStart(2, '0');
                                                const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
                                                const year = date.getFullYear();
                                                return `${day}-${month}-${year}`;
                                            };
                                            return (
                                                <div className='col-md-3 news-crd '>

                                                    <div className="card" style={{ width: "" }}>
                                                        <img src={rec1} alt="" style={{ width: "" }} />
                                                        <div className="card-body">
                                                            <a href='' >  <b className="card-text text-capitalize">

                                                                {truncateWords(data.title, 10)}
                                                            </b>
                                                            </a>
                                                            <p className='text-capitalize'>{data.content}</p>

                                                            <p className='news-crd2'>
                                                                Created At : {formatDate(data.created_at)}</p>
                                                        </div>
                                                    </div>


                                                </div>

                                            )
                                        })
                                        }


                                    </div>


                                </div> */}





                                        {/* contact section */}
                                        <div className='container divider-crd'>
                                            <div class="card py-2 px-3">

                                                <div className="card-body p-0">

                                                    <div className='pt-4'>
                                                        <h5>Reviews for {UniversityInfo.name}</h5>
                                                    </div>
                                                    {isLoading ? (
                                                        <div className='text-center'>
                                                            <Spinner animation="border" role="status">
                                                                <span className="visually-hidden">Loading...</span>
                                                            </Spinner>
                                                        </div>
                                                    ) : (


                                                        <div className='row'>



                                                            {reviews.map((data) => {
                                                                return (

                                                                    <div>
                                                                        <section className="content-wrapper-faq">


                                                                            <div className="main_reviews-container">
                                                                                <div className="py-3 my-2 border-dark rounded reviews_inner container">
                                                                                    <div className="accordian-link d-flex align-items-center pointer py-2 ">
                                                                                        <LuUserCircle className="img-fluid user_image" /> <h3 className="reveiew_user_name ps-3">{data.author} &nbsp; &nbsp; <img src={`https://media.nayeedisha.in/gallery/${data.username}${data.rating}.png`} className="w-100 userstar" alt='Nayeedisha' /></h3>

                                                                                    </div>
                                                                                    <p className="review_answer">{data.content}
                                                                                        .</p>
                                                                                </div>
                                                                            </div>

                                                                        </section>
                                                                        <hr>
                                                                        </hr>








                                                                    </div>

                                                                )
                                                            })
                                                            }



                                                        </div>
                                                    )}







                                                </div>
                                            </div>
                                        </div>





                                        {/* Gallery Section */}

                                        <div className='container slider-section mb-5'>
                                            <div className='card pb-4'>
                                                <div className='card-body'>
                                                    <h3>Images & Video Gallery</h3>
                                                    <div className="App pt-3" style={{ background: "" }}>
                                                        {isLoading ? (
                                                            <div className='text-center'>
                                                                <Spinner animation="border" role="status">
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </Spinner>
                                                            </div>
                                                        ) : (
                                                            <Carousel images={images} style={{ height: '400px', width: '100%' }} />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>






                                        {/* FAQ SECTION */}

                                        <div className='container faq-section'>


                                            <div className='card'>

                                                <div className='card-body'>

                                                    <h3>FAQs about {UniversityInfo.name} University</h3>

                                                    {isLoading ? (
                                                        <div className='text-center'>
                                                            <Spinner animation="border" role="status">
                                                                <span className="visually-hidden">Loading...</span>
                                                            </Spinner>
                                                        </div>
                                                    ) : (
                                                        <div className='roww'>

                                                            {FAQ.map((data) => {

                                                                return (
                                                                    <div className="accordion mt-4" id="accordionExample">
                                                                        <div className="accordion-item">
                                                                            <h2 className="accordion-header mb-2" id="headingOne">
                                                                                <button className="accordion-button" type="button" data-bs-toggle="collapse open" data-bs-target={`#collapseOne${data.id}`} aria-expanded="true" aria-controls="collapseOne">
                                                                                    {data.question ? data.question : 'Null'}
                                                                                </button>
                                                                            </h2>
                                                                            <div id={`collapseOne${data.id}`} className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                                                <div class="accordion-body">
                                                                                    {data.answer ? data.answer : 'Null'}
                                                                                </div>
                                                                            </div>
                                                                        </div><hr></hr>

                                                                    </div>
                                                                )
                                                            })
                                                            }

                                                        </div>
                                                    )}

                                                </div>
                                            </div>


                                        </div>










                                        {/* Register With Us */}





                                    </div>

                                    {/*  */}
                                    {/*  */}
                                    {/* COURSE PAGES 1 */}

                                    <div class="tab-pane fade" id="nav-courses" role="tabpanel" aria-labelledby="nav-courses-tab">


                                        <section id="courses">
                                            <div className='container divider-crd'>
                                                <div class="card mt-3 mb-5">
                                                    <div class="card-body">
                                                        {/* <h5>Courses & Fees</h5> */}

                                                        {isLoading ? (
                                                            <div className='text-center'>
                                                                <Spinner animation="border" role="status">
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </Spinner>
                                                            </div>
                                                        ) : (

                                                            <div className='row'>



                                                                {courses.map((data) => {
                                                                    return (
                                                                        <div>
                                                                            <p><b>About the course</b> :
                                                                                {/* {data.about_course ? data.about_course : 'Null'} */}
                                                                                <div dangerouslySetInnerHTML={{ __html: data.about_course || '' }} />

                                                                            </p>
                                                                            <hr></hr>
                                                                            <h5 className='mt-3'>Course Facts:</h5>
                                                                            <div className='col-lg-12 col-md-12 d-flex justify-content-between pt-1'>


                                                                                <table class="table table-bordered">
                                                                                    <thead>
                                                                                        {data.name ?
                                                                                            <tr>

                                                                                                <th scope="col">Course Name</th>
                                                                                                <td scope="col">{data.name ? data.name : 'Null'}</td>

                                                                                            </tr>
                                                                                            : null}

                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {data.duration ?
                                                                                            <tr>

                                                                                                <th>Duration</th>
                                                                                                <td>{data.duration ? data.duration : 'Null'}</td>

                                                                                            </tr>
                                                                                            : null
                                                                                        }
                                                                                        {data.teacher_student_ratio ?
                                                                                            <tr>

                                                                                                <th>Teacher Student Ratio</th>
                                                                                                <td>{data.teacher_student_ratio ? data.teacher_student_ratio : 'Null'}</td>

                                                                                            </tr>
                                                                                            : null
                                                                                        }
                                                                                        {data.total_budget ?
                                                                                            <tr>
                                                                                                <th>Total Budget</th>
                                                                                                <td>{data.total_budget ? data.total_budget : 'Null'}  {data.currency ? data.currency : 'Null'} </td>
                                                                                            </tr>
                                                                                            : null
                                                                                        }


                                                                                    </tbody>
                                                                                </table>
                                                                            </div>


                                                                        </div>

                                                                    )
                                                                })
                                                                }



                                                            </div>
                                                        )}








                                                    </div>
                                                </div>
                                            </div>
                                        </section>

                                        {/* another section */}
                                        <div className='fee-section mt-5 '>
                                            <div className='container p-4'>


                                                <div className='row fee-section-2 p-4 '>
                                                    <div className='col-md-6 pt-3'>

                                                        <h3>Can’t find right guidance?</h3>
                                                        <p>No Problem! Speak to our experts safely from your home.</p>

                                                        <a href='tel: +91 8860031900' ><button type="button" class="btn " >Call Us : +91 8860031900</button></a>


                                                    </div>

                                                    <div className='col-md-6 text-center'>

                                                        <img src={loanimg} alt="Nayeedisha" style={{ width: "" }} />


                                                    </div>

                                                </div>
                                            </div>


                                        </div>

                                        {/*  */}
                                        {/*  */}

                                    </div>

                                    {/* SECTION 1 END */}

                                    <div class="tab-pane fade" id="nav-program" role="tabpanel" aria-labelledby="nav-program-tab">

                                        {/* streams section */}

                                        <div className='container divider-crd'>
                                            <div class="card mt-3 mb-5">
                                                <div class="card-body">



                                                    {/* <h5>Admission & Eligibility</h5> */}

                                                    {isLoading ? (
                                                        <div className='text-center'>
                                                            <Spinner animation="border" role="status">
                                                                <span className="visually-hidden">Loading...</span>
                                                            </Spinner>
                                                        </div>
                                                    ) : (

                                                        <div className='row'>
                                                            {AdmissionandEligibility.map((data) => {
                                                                return (

                                                                    <div><br></br>

                                                                        <div className='col-lg-12 col-md-12 d-flex justify-content-between pt-1'>
                                                                            {/* <p>{data.eligibility_criteria}</p> */}

                                                                            <div dangerouslySetInnerHTML={{ __html: data.eligibility_criteria || '' }} />
                                                                        </div>
                                                                        <br></br>

                                                                        <div className='col-lg-12 col-md-12 d-flex justify-content-between pt-1'>

                                                                            <div dangerouslySetInnerHTML={{ __html: data.documents_required || '' }} />
                                                                        </div><br></br>

                                                                        <div className='col-lg-12 col-md-12 d-flex justify-content-between pt-1'>

                                                                            <div dangerouslySetInnerHTML={{ __html: data.why_choose_nayeedisha || '' }} />
                                                                        </div>



                                                                    </div>

                                                                )
                                                            })
                                                            }



                                                        </div>
                                                    )}

                                                </div>
                                            </div>
                                        </div>


                                        {/* end stream section */}








                                    </div>

                                    {/*  */}
                                    {/* SECTION 1 END */}

                                    <div class="tab-pane fade" id="nav-admission" role="tabpanel" aria-labelledby="nav-admission-tab">

                                        {/* streams section */}

                                        <section id="advantage">
                                            <div className='container divider-crd'>
                                                <div class="card mt-3 mb-5">
                                                    <div class="card-body">
                                                        <h5>Why should you select {UniversityInfo.name} ?</h5>
                                                        {isLoading ? (
                                                            <div className='text-center'>
                                                                <Spinner animation="border" role="status">
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </Spinner>
                                                            </div>
                                                        ) : (

                                                            <div className='row'>

                                                                <div dangerouslySetInnerHTML={{ __html: UniversityInfo.advantage || '' }} />






                                                            </div>
                                                        )}








                                                    </div>
                                                </div>
                                            </div>
                                        </section>


                                        {/* end stream section */}








                                    </div>


                                    {/*  */}





                                    <div class="tab-pane fade" id="nav-scholarship" role="tabpanel" aria-labelledby="nav-scholarship-tab">


                                        <section id="campus">
                                            <div className='container divider-crd'>
                                                <div class="card mt-3 mb-5">
                                                    <div class="card-body">



                                                        <h5>Campus of {UniversityInfo.name}</h5>

                                                        {isLoading ? (
                                                            <div className='text-center'>
                                                                <Spinner animation="border" role="status">
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </Spinner>
                                                            </div>
                                                        ) : (


                                                            <div className='row'>



                                                                {Campus.map((data) => {
                                                                    return (

                                                                        <div>
                                                                            <p>

                                                                                <div dangerouslySetInnerHTML={{ __html: data.about_campus || '' }} />

                                                                            </p>



                                                                            <p>

                                                                                <div dangerouslySetInnerHTML={{ __html: data.hostel || '' }} />

                                                                            </p>

                                                                            <p>

                                                                                <div dangerouslySetInnerHTML={{ __html: data.infrastructure || '' }} />

                                                                            </p>


                                                                            <p>
                                                                                <div dangerouslySetInnerHTML={{ __html: data.student_life || '' }} />

                                                                            </p>









                                                                        </div>

                                                                    )
                                                                })
                                                                }



                                                            </div>
                                                        )}








                                                    </div>
                                                </div>
                                            </div>
                                        </section>




                                    </div>





                                    <div class="tab-pane fade" id="nav-placement" role="tabpanel" aria-labelledby="nav-placement-tab">


                                        <p></p>




                                    </div>




                                    <div class="tab-pane fade" id="nav-cutoff" role="tabpanel" aria-labelledby="nav-cutoff-tab">


                                        {/* streams section */}

                                        <div className='container mt-5 mb-5 stream-section'>

                                            <div className='card'>

                                                <div className='card-body'>


                                                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                                                        <li class="nav-item" role="presentation">
                                                            <button class="nav-link active" id="exam-tab" data-bs-toggle="tab" data-bs-target="#exam" type="button" role="tab" aria-controls="exam" aria-selected="true">EXAM</button>
                                                        </li>
                                                        <li class="nav-item" role="presentation">
                                                            <button class="nav-link" id="degree-tab" data-bs-toggle="tab" data-bs-target="#degree" type="button" role="tab" aria-controls="degree" aria-selected="false">DEGREE</button>
                                                        </li>
                                                        <li class="nav-item" role="presentation">
                                                            <button class="nav-link" id="course-tab" data-bs-toggle="tab" data-bs-target="#course" type="button" role="tab" aria-controls="course" aria-selected="false">COURSE </button>
                                                        </li>
                                                    </ul>
                                                    <div class="tab-content px-3 mt-4" id="myTabContent">
                                                        <div class="tab-pane fade show active" id="exam" role="tabpanel" aria-labelledby="exam-tab">
                                                            <input type="checkbox" id="cat" name="cat" value="edu" />
                                                            <label for="cat"> &nbsp; CAT</label><br></br>

                                                        </div>
                                                        <div class="tab-pane fade" id="degree" role="tabpanel" aria-labelledby="degree-tab">
                                                            <input type="checkbox" id="mba" name="mba" value="edu" />
                                                            <label for="mba"> &nbsp; M.B.A. (Master of Business Administration)</label> &nbsp;&nbsp;&nbsp;


                                                        </div>
                                                        <div class="tab-pane fade" id="course" role="tabpanel" aria-labelledby="course-tab">
                                                            <input type="checkbox" id="course1" name="course1" value="edu" />
                                                            <label for="course1"> &nbsp; MBA in Marketing</label>&nbsp;&nbsp;&nbsp;
                                                            <input type="checkbox" id="course1" name="course1" value="edu" />
                                                            <label for="course1"> &nbsp; MBA in Finance
                                                            </label>&nbsp;&nbsp;&nbsp;
                                                            <input type="checkbox" id="course1" name="course1" value="edu" />
                                                            <label for="course1"> &nbsp; MBA in Human Resource Management</label>&nbsp;&nbsp;&nbsp;
                                                            <input type="checkbox" id="course1" name="course1" value="edu" />
                                                            <label for="course1"> &nbsp; MBA in Business Analytics</label>&nbsp;&nbsp;

                                                        </div>
                                                    </div>


                                                    <hr></hr>
                                                    <div className='pt-4'>
                                                        <p><b>Applied Filters:</b>
                                                            &nbsp;  No Filters Applied</p></div>





                                                </div>

                                            </div>

                                        </div>


                                        {/* end stream section */}


                                        <div className='container mb-5 cut-off-section '>


                                            <div className='card'>


                                                <div className='card-body'>
                                                    <h5>DIT University (CAT, MBA in Marketing, Cutoff, Round 1, 2018)</h5>
                                                    <hr></hr>
                                                    <table class="table">
                                                        <thead>
                                                            <tr>

                                                                <th scope="col">Category</th>
                                                                <th scope="col">Closing Percentile</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>

                                                                <td>General</td>
                                                                <td>70</td>

                                                            </tr>


                                                        </tbody>
                                                    </table>






                                                </div>










                                            </div>





                                        </div>


























                                    </div>


                                    <div class="tab-pane fade" id="nav-campus" role="tabpanel" aria-labelledby="nav-campus-tab">


                                        <p>campus page</p>




                                    </div>



                                    <div class="tab-pane fade" id="nav-gallery" role="tabpanel" aria-labelledby="nav-gallery-tab">


                                        {/* first section */}

                                        <div className='congtainer mt-5 mb-5'>

                                            <div className='card'>

                                                <div className='card-body'>

                                                    <div className='row'>

                                                        <div className='col mt-3'>
                                                            <img src={galleriestab} alt="Nayeedisha" style={{ width: "" }} />
                                                        </div>

                                                        <div className='col mt-3'>
                                                            <img src={galleriestab} alt="Nayeedisha" style={{ width: "" }} />
                                                        </div>

                                                        <div className='col mt-3'>
                                                            <img src={galleriestab} alt="Nayeedisha" style={{ width: "" }} />
                                                        </div>
                                                        <div className='col mt-3'>
                                                            <img src={galleriestab} alt="Nayeedisha" style={{ width: "" }} />
                                                        </div>
                                                        <div className='col mt-3'>
                                                            <img src={galleriestab} alt="Nayeedisha" style={{ width: "" }} />
                                                        </div>

                                                    </div>

                                                </div>

                                            </div>

                                        </div>


                                    </div>
                                    <div class="tab-pane fade" id="nav-reviews" role="tabpanel" aria-labelledby="nav-reviews-tab">

                                        <div className='container divider-crd'>
                                            <div class="card py-2 px-3">

                                                <div className="card-body p-0">

                                                    <div className='pt-4'>
                                                        <h5>Reviews for {UniversityInfo.name}</h5>
                                                    </div>
                                                    {isLoading ? (
                                                        <div className='text-center'>
                                                            <Spinner animation="border" role="status">
                                                                <span className="visually-hidden">Loading...</span>
                                                            </Spinner>
                                                        </div>
                                                    ) : (


                                                        <div className='row'>



                                                            {reviews.map((data) => {
                                                                return (

                                                                    <div>
                                                                        <section className="content-wrapper-faq">


                                                                            <div className="main_reviews-container">
                                                                                <div className="py-3 my-2 border-dark rounded reviews_inner container">
                                                                                    <div className="accordian-link d-flex align-items-center pointer py-2 ">
                                                                                        <LuUserCircle className="img-fluid user_image" /> <h3 className="reveiew_user_name ps-3">{data.author} &nbsp; &nbsp; <img src={`https://media.nayeedisha.in/gallery/${data.username}${data.rating}.png`} className="w-100 userstar" alt='Nayeedisha' /></h3>

                                                                                    </div>
                                                                                    <p className="review_answer">{data.content}
                                                                                        .</p>
                                                                                </div>
                                                                            </div>

                                                                        </section>
                                                                        <hr>
                                                                        </hr>








                                                                    </div>

                                                                )
                                                            })
                                                            }



                                                        </div>
                                                    )}







                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    {/*  */}
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-4'>
                                <div className="banner-copy-form p-4 nav-ani" style={{ zIndex: 9 }}>
                                    <h5 className='aniafter anibanner'> &nbsp; &nbsp; Get Free Counselling</h5>
                                    <form ref={formRef} className="align" onSubmit={submitForm} >
                                        <div className="mb-3">
                                            <label className="form-label">
                                                Full Name <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                name="name"
                                                className="form-control"
                                                placeholder="Full Name"
                                                required
                                            />

                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">
                                                Phone <span className="text-danger">*</span>
                                            </label>
                                            <PhoneInput inputProps={{ name: 'phone_number', required: true, autoFocus: false }} country={'in'} onChange={(phone) => setPhoneNumberr(phone)} />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">
                                                Select State <span className="text-danger">*</span>
                                            </label>
                                            <select name="state" className="form-control">
                                                <option value="">Please Select Preferred State</option>
                                                <option value="Delhi NCR">Delhi NCR</option>
                                                <option value="Uttar Pradesh">Uttar Pradesh</option>
                                                <option value="Andhra Pradesh">Andhra Pradesh</option>
                                                <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                                <option value="Assam">Assam</option>
                                                <option value="Bihar">Bihar</option>
                                                <option value="Chhattisgarh">Chhattisgarh</option>
                                                <option value="Gujarat">Gujarat</option>
                                                <option value="Haryana">Haryana</option>
                                                <option value="Himachal Pradesh">Himachal Pradesh</option>
                                                <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                                                <option value="Goa">Goa</option>
                                                <option value="Jharkhand">Jharkhand</option>
                                                <option value="Karnataka">Karnataka</option>
                                                <option value="Kerala">Kerala</option>
                                                <option value="Madhya Pradesh">Madhya Pradesh</option>
                                                <option value="Maharashtra">Maharashtra</option>
                                                <option value="Manipur">Manipur</option>
                                                <option value="Meghalaya">Meghalaya</option>
                                                <option value="Mizoram">Mizoram</option>
                                                <option value="Nagaland">Nagaland</option>
                                                <option value="Odisha">Odisha</option>
                                                <option value="Punjab">Punjab</option>
                                                <option value="Rajasthan">Rajasthan</option>
                                                <option value="Sikkim">Sikkim</option>
                                                <option value="Tamil Nadu">Tamil Nadu</option>
                                                <option value="Telangana">Telangana</option>
                                                <option value="Tripura">Tripura</option>
                                                <option value="Uttarakhand">Uttarakhand</option>
                                                <option value="West Bengal">West Bengal</option>
                                                <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                                                <option value="Chandigarh">Chandigarh</option>
                                                <option value="Dadra and Nagar Haveli">Dadra and Nagar Haveli</option>
                                                <option value="Daman and Diu">Daman and Diu</option>
                                                <option value="Delhi">Delhi</option>
                                                <option value="Lakshadweep">Lakshadweep</option>
                                                <option value="Puducherry">Puducherry</option>
                                            </select>

                                        </div>
                                        <div className="cp-but mt-4">
                                            <button type="submit" className="btn btn-primary">Submit</button>
                                        </div>
                                        {successMessage && <p className="text-success">{successMessage}</p>}
                                        {errorMessage && <p className="text-danger">{errorMessage}</p>}
                                    </form>
                                </div>
                                <div className="row banner-copy-form p-4 m-0 blogsticky">
                                    <h5 className='anibanner '>Related Blogs</h5>
                                    {isLoading ? (
                                        // Skeleton loader when data is loading
                                        Array.from({ length: 4 }).map((_, index) => (
                                            <div className="col-md-4 mt-3" key={index}>
                                                <div className="card" style={{ width: '100%' }}>
                                                    <Skeleton height={150} />
                                                    <div className="card-body crdr1">
                                                        <Skeleton height={20} width="80%" style={{ marginBottom: 6 }} />
                                                        <Skeleton height={10} width="60%" style={{ marginBottom: 6 }} />
                                                        <Skeleton height={10} width="80%" />
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        // Render blog posts when data is available, limited to 4 items
                                        blogdetails.slice(0, 4).map((blog) => {
                                            // Truncate the content
                                            const truncatedContent = blog.title?.split(' ').slice(0, 7).join(' ');
                                            // Format the date
                                            const createdAtDate = new Date(blog.created_at);
                                            const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                                            const day = createdAtDate.getDate();
                                            const monthIndex = createdAtDate.getMonth();
                                            const year = createdAtDate.getFullYear();
                                            const formattedDate = `${day} ${months[monthIndex]} ${year}`;

                                            return (
                                                // Each blog post rendering
                                                <div className="col-md-12 mt-3" key={blog.slug}>
                                                    <Link to={`/blogs/${blog.slug}`}>
                                                        <div className="card crdr1" style={{ width: '100%' }}>
                                                            <div className='row'>
                                                                <div className='col-md-4'>
                                                                    <img src={blog.thumbnail} className="w-100 pagedetailsblog" alt={blog.title} />
                                                                </div>
                                                                <div className='col-md-8'>
                                                                    <div className="card-body">
                                                                        <div className="row">
                                                                            <div className="col-md-12 col-lg-12 col-12">
                                                                                <b style={{ textTransform: 'capitalize', fontWeight: '600' }}>
                                                                                    {truncatedContent}..
                                                                                </b>
                                                                                <p className="py-2">
                                                                                    <img src={Images} className="blogcalander" /> : {formattedDate}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            );
                                        })
                                    )}

                                </div>
                            </div>
                        </div>

                    </div>
                )
            )}
        </>
    );

};

export default University;
