import React, { useEffect, useState } from "react";
import { GrView } from "react-icons/gr";
import { Link } from "react-router-dom";
import Skeleton from 'react-loading-skeleton';
import { Helmet } from "react-helmet";

// Initialize a simple in-memory cache
const cache = {
    blogdetails: null,
};

const Blog = () => {
    const [blogdetails, setBlogdetails] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchBlogDetails = async () => {
            setIsLoading(true);
            if (cache.blogdetails) {
                setBlogdetails(cache.blogdetails);
                setIsLoading(false);
            } else {
                try {
                    const response = await fetch('https://webcrmapi.nayeedisha.in/api/blog-details');
                    const data = await response.json();
                    cache.blogdetails = data;
                    setBlogdetails(data);
                } catch (error) {
                    console.error('Error fetching blog details:', error);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        fetchBlogDetails();
    }, []);

    return (
        <>
            <div className="application">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Blogs | Latest Blogs on Courses, Colleges and Exams - Nayeedisha.in</title>
                    <link rel="canonical" href="https://nayeedisha.in" />
                </Helmet>
            </div>

            {/* section1 */}
            <div className='abt-section-news pt-5 pb-5 text-center'>
                <div className='container'>
                    <h3 className="text-white"> Latest Blogs & Stories</h3>
                    <div className='bds-news'></div>
                    <div id='brdrs'></div>
                </div>
            </div>

            <div className="section5">
                <div className="container pt-5 pb-5">
                    <div className="row">
                        {isLoading ? (
                            // Skeleton loader when data is loading
                            Array.from({ length: 6 }).map((_, index) => (
                                <div className="col-md-4 mt-3" key={index}>
                                   <div className="card" style={{ width: '100%' }}>
                                        <Skeleton height={150} />
                                        <div className="card-body crdr1">
                                            <Skeleton height={20} width="80%" style={{ marginBottom: 6 }} />
                                            <Skeleton height={10} width="60%" style={{ marginBottom: 6 }} />
                                            <Skeleton height={10} width="80%" />
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            // Render blog posts when data is available
                            blogdetails.map((blog) => {
                                // Truncate the content
                                const truncatedContent = blog.content?.split(' ').slice(0, 30).join(' ');
                                // Format the date
                                const createdAtDate = new Date(blog.created_at);
                                const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                                const day = createdAtDate.getDate();
                                const monthIndex = createdAtDate.getMonth();
                                const year = createdAtDate.getFullYear();
                                const formattedDate = `${day} ${months[monthIndex]} ${year}`;
                                return (
                                    // Each blog post rendering
                                    <div className="col-md-4 mt-3" key={blog.slug}>
                                        <Link to={`/blogs/${blog.slug}`}>
                                            <div className="card crdr1" style={{ width: '100%' }}>
                                                <img src={blog.thumbnail} className="w-100 blog" alt={blog.title} />
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-12 col-lg-12 col-12">
                                                            <b style={{ textTransform: 'capitalize' }}>
                                                                {/* {blog.title ? blog.title : 'Not found'}  */}
                                                                {blog.title
    ? blog.title.split(' ').slice(0, 8).join(' ') + 
      (blog.title.split(' ').length > 8 ? '...' : '')
    : 'Null'}
                                                                </b>
                                                            <div className="d-flex justify-content-between">
                                                                <p className="py-2">{formattedDate}</p>
                                                                <p className="py-2"><GrView />&nbsp;{blog.views}</p>
                                                            </div>
                                                            <div dangerouslySetInnerHTML={{ __html: truncatedContent || '' }} /><Link to={`/blogs/${blog.slug}`}> View More</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                );
                            })
                        )}
                    </div>
                </div>
            </div>
            <br />
        </>
    );
};

export default Blog;
