import React, { createContext, useContext, useState } from 'react';

const ComparisonContext = createContext();

export const useComparison = () => useContext(ComparisonContext);

export const ComparisonProvider = ({ children }) => {
    const [comparisonData, setComparisonData] = useState(null);

    return (
        <ComparisonContext.Provider value={{ comparisonData, setComparisonData }}>
            {children}
        </ComparisonContext.Provider>
    );
};