import React, { useEffect, useState } from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Accordion from 'react-bootstrap/Accordion';
import searchicon from '../img/search-icon.png';
import { Spinner, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { TfiViewListAlt, TfiViewGrid } from "react-icons/tfi";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Pagination from 'react-bootstrap/Pagination';
import { GrPowerReset } from "react-icons/gr";
import Badge from '@mui/material/Badge';
import { BsFilterCircleFill } from "react-icons/bs";
const Universitylist = () => {
    const [universities, setUniversities] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [filteredUniversities, setFilteredUniversities] = useState([]);
    const [courseFilter, setCourseFilter] = useState('');
    const [stateFilter, setStateFilter] = useState('');
    const [cityFilter, setCityFilter] = useState('');
    const [studyModeFilter, setStudyModeFilter] = useState('');
    const [countryFilter, setCountryFilter] = useState('');
    const [filterOptions, setFilterOptions] = useState({
        courses: [],
        countries: {},
        states: [],
        cities: [],
    });
    const [viewMode, setViewMode] = useState('grid');
    const [currentPage, setCurrentPage] = useState(1);
    const [universitiesPerPage] = useState(7);

    const [colleges, setColleges] = useState([]);
    const [showDropdown, setShowDropdown] = useState([false, false, false]);


        // Effect to set the first country as the default filter
        useEffect(() => {
            const countries = Object.keys(filterOptions.countries);
            if (countries.length > 0 && !countryFilter) {
                setCountryFilter(countries[0]); // Set the first country as the default
            }
        }, [filterOptions.countries, countryFilter]);

        

    useEffect(() => {
        fetch('https://webcrmapi.nayeedisha.in/api/university-details')
            .then(response => response.json())
            .then(data => setColleges(data))
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    const handleAddCollegeClick = (index) => {
        const newShowDropdown = showDropdown.map((value, idx) => (idx === index ? true : value));
        setShowDropdown(newShowDropdown);
    };

    
    // Debugging useEffect to log countryFilter whenever it updates
    useEffect(() => {
        console.log("Current countryFilter: ", countryFilter);
    }, [countryFilter]);

  

    useEffect(() => {
        fetch('https://webcrmapi.nayeedisha.in/api/university-list')
            .then((response) => response.json())
            .then((data) => {
                setUniversities(data);
                setIsLoading(false);

                const courses = [...new Set(data.map(university => university.course))].filter(Boolean);
                const countries = data.reduce((acc, university) => {
                    acc[university.country] = (acc[university.country] || 0) + 1;
                    return acc;
                }, {});
                const states = [...new Set(data.map(university => university.state))].filter(Boolean);
                const cities = [...new Set(data.map(university => university.city))].filter(Boolean);

                setFilterOptions({ courses, countries, states, cities });
                setFilteredUniversities(data); // Initialize with all data
            })
            .catch((error) => {
                setIsLoading(false);
                console.error('Error fetching data:', error);
            });
    }, []);

    useEffect(() => {
        filterUniversities();
    }, [courseFilter, stateFilter, cityFilter, studyModeFilter, countryFilter]);

    const filterUniversities = () => {
        let filtered = universities;
        if (countryFilter) {
            filtered = filtered.filter(university => university.country === countryFilter);
        }
        if (stateFilter) {
            filtered = filtered.filter(university => university.state === stateFilter);
        }
        if (cityFilter) {
            filtered = filtered.filter(university => university.city === cityFilter);
        }
        if (courseFilter) {
            filtered = filtered.filter(university => university.course === courseFilter);
        }
     
     
     
        

        setFilteredUniversities(filtered);
        setCurrentPage(1); // Reset to the first page after filtering
    };

    useEffect(() => {
        if (countryFilter) {
            const country = [...new Set(universities.filter(university => university.country === countryFilter).map(university => university.country))].filter(Boolean);
            const states = [...new Set(universities.filter(university => university.country === countryFilter).map(university => university.state))].filter(Boolean);
            const cities = [...new Set(universities.filter(university => university.country === countryFilter).map(university => university.city))].filter(Boolean);
            setFilterOptions(prevOptions => ({
                ...prevOptions,
                country,
                states,
                cities,
            }));
            setStateFilter('');
            setCityFilter('');
        } else {
            const country = [...new Set(universities.map(university => university.country))].filter(Boolean);
            const states = [...new Set(universities.map(university => university.state))].filter(Boolean);
            const cities = [...new Set(universities.map(university => university.city))].filter(Boolean);
            setFilterOptions(prevOptions => ({
                ...prevOptions,
                country,
                states,
                cities,
            }));
        }
    }, [countryFilter, universities]);


    const handleClick = (event) => {
        setCurrentPage(Number(event.target.id));
    };

    const indexOfLastUniversity = currentPage * universitiesPerPage;
    const indexOfFirstUniversity = indexOfLastUniversity - universitiesPerPage;
    const currentUniversities = filteredUniversities.slice(indexOfFirstUniversity, indexOfLastUniversity);

    const renderPageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredUniversities.length / universitiesPerPage); i++) {
        renderPageNumbers.push(i);
    }

    return (
        <>
            <div className='container pt-3'>
                <Breadcrumb>
                    <Breadcrumb.Item href="#" id="crumb">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="" id="crumb">Study Abroad</Breadcrumb.Item>
                </Breadcrumb>
            </div>

            <div className='list-section pt-3 pb-3'>
                <div className='container'>
                    <h6>List Of {isLoading ? <Skeleton width={200} /> : ` ${universities.length} Universities in Abroad`}</h6>
                    <p>We At NayeeDisha offers MBBS abroad to top-notch universities with A to Z preparation through a proficient counsellors team. We approach 15+ counties & holding experience more than 13+ years of.</p>
                </div>
            </div>

            <div className='container mt-5 mb-5 mobile-top'>
                <div className='row'>
                    <div className='col-lg-3 col-md-5 mt-3 flexyr'>
                        <div className="card lister-card">
                            <div className="card-body">
                                <h6>Filters</h6>
                                <div className='d-flex justify-content-between'>
                                    <p>Total {universities.length} Universities</p>
                                    <a type='button' id='clr' onClick={() => {
                                        setCourseFilter('');
                                        setStateFilter('');
                                        setCityFilter('');
                                        setStudyModeFilter('');
                                        setCountryFilter('');
                                    }}>Reset</a>
                                </div>
                                <hr />

                                {/* Country Filter */}
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header className='header-accord'>Country</Accordion.Header>
                                        <Accordion.Body>
                                            <div className='pt-4 d-none'>
                                                <form className="example ">
                                                    <input type="text" placeholder="Find Country" name="search" />
                                                    <button type="submit">
                                                        <img src={searchicon} className="search-icon" alt="" />
                                                    </button>
                                                </form>
                                            </div>
                                            <div className='pt-2'>
                                                {Object.keys(filterOptions.countries).map(country => (
                                                    <div className="form-check form-check-inline w-100" key={country}>
                                                        <input
                                                            className="form-check-input mt-2"
                                                            type="checkbox"
                                                            id={country}
                                                            name="country"
                                                            value={country}
                                                            checked={countryFilter == country}
                                                            onChange={(e) => setCountryFilter(e.target.value)}
                                                         
                                                        />
                                                        <label htmlFor={country} className='form-check-label w-100 mt-2'>&nbsp; {country} ({filterOptions.countries[country]})</label>
                                                        <br />
                                                    </div>
                                                ))}
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                                <hr />

                                {/* State Filter */}
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header className='header-accord'>State</Accordion.Header>
                                        <Accordion.Body>
                                            <div className='pt-4 d-none'>
                                                <form className="example">
                                                    <input type="text" placeholder="Find State" name="search" />
                                                    <button type="submit">
                                                        <img src={searchicon} className="search-icon" alt="" />
                                                    </button>
                                                </form>
                                            </div>
                                            <div className='pt-2'>
                                                {filterOptions.states?.map(state => (
                                                    <div className="form-check form-check-inline w-100" key={state}>
                                                        <input
                                                            className="form-check-input mt-2"
                                                            type="checkbox"
                                                            id={state}
                                                            name="state"
                                                            value={state}
                                                            checked={stateFilter === state}
                                                            onChange={(e) => setStateFilter(e.target.value)}
                                                        />
                                                        <label htmlFor={state} className='form-check-label w-100 mt-2'>&nbsp; {state}</label>
                                                        <br />
                                                    </div>
                                                ))}
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                                <hr />

                                {/* City Filter */}
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header className='header-accord'>City</Accordion.Header>
                                        <Accordion.Body>
                                            <div className='pt-4 d-none'>
                                                <form className="example">
                                                    <input type="text" placeholder="Find City" name="search" />
                                                    <button type="submit">
                                                        <img src={searchicon} className="search-icon" alt="" />
                                                    </button>
                                                </form>
                                            </div>
                                            <div className='pt-2'>
                                                {filterOptions.cities?.map(city => (
                                                    <div className="form-check form-check-inline w-100" key={city}>
                                                        <input
                                                            className="form-check-input mt-2"
                                                            type="checkbox"
                                                            id={city}
                                                            name="city"
                                                            value={city}
                                                            checked={cityFilter === city}
                                                            onChange={(e) => setCityFilter(e.target.value)}
                                                        />
                                                        <label htmlFor={city} className='form-check-label w-100 mt-2'>&nbsp; {city}</label>
                                                        <br />
                                                    </div>
                                                ))}
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-9 col-md-12 col-sm-12 mt-2">
                        <div className='bg-mobile'>
                            <Badge badgeContent={1} color="primary">   <BsFilterCircleFill className='f-27 filter' data-bs-toggle="offcanvas" data-bs-target="#offcanvasTop" aria-controls="offcanvasTop"></BsFilterCircleFill></Badge>
                            <div className="grid-view">
                                Sort by &nbsp; : &nbsp;
                                <TfiViewGrid onClick={() => setViewMode('grid')} className={viewMode === 'grid' ? 'active' : ''} />
                                &nbsp; / &nbsp;
                                <TfiViewListAlt onClick={() => setViewMode('list')} className={viewMode === 'list' ? 'active' : ''} />
                            </div>
                        </div>
                        {isLoading ? (
                            <div className="row">
                                {Array(6).fill().map((_, index) => (
                                    <div className="col-lg-4 col-md-6 col-sm-12 mb-3" key={index}>
                                        <div className="card" style={{ width: '100%' }}>
                                            <Skeleton height={135} />
                                            <div className="card-body">
                                                <Skeleton circle={true} height={50} width={50} />
                                                <Skeleton count={3} />
                                                <div className="d-flex justify-content-between mt-3">
                                                    <Skeleton width={100} height={40} />
                                                    <Skeleton width={100} height={40} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="row">

                                {currentUniversities.length === 0 ? (
                                    <div className="col-12 text-center">No data found</div>
                                ) : (
                                    currentUniversities.map((data) => (
                                        viewMode === 'list' ? (
                                            <div className="col-lg-12 col-md-12 col-sm-4 mt-3" key={data.uid}>
                                                <div className="card" id="icons-crd" style={{ width: '100%' }}>
                                                    <div
                                                        className="university-section pt-5 pb-5"
                                                        style={{
                                                            width: '100%',
                                                            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${data.cover_image})`,
                                                            backgroundRepeat: 'no-repeat',
                                                            backgroundSize: '100% 186%',
                                                            height: '135px',
                                                            backgroundPosition: 'center'
                                                        }}
                                                    ></div>
                                                    <div className="card-body iconerss-card">
                                                        <img src={data.logo} className="" alt="" />
                                                        <div className='row'>
                                                            <div className='col-md-6 col-7'>
                                                                <Link to={`/university/${data.slug}`}>
                                                                    <h5 className="mt-4 text-transform">{data.name}</h5>
                                                                </Link>
                                                                <div>
                                                                    <div className="">
                                                                        <p className="para1" style={{ color: '#000' }}>
                                                                            Establish Year :
                                                                            <span className="ugc" style={{ color: '#000' }}>
                                                                                {data.estb ? data.estb : 'Not found'}
                                                                            </span>
                                                                        </p>
                                                                        <p className="para1" style={{ color: '#000' }}>
                                                                            Country : &nbsp;
                                                                            <span className="ugc" style={{ color: '#000' }}>
                                                                                {data.country ? data.country : 'Not found'}
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-6 col-5 text-end'>
                                                                <div className="mt-4 pt-1">
                                                                    <Button
                                                                        type="button"
                                                                        className="btn btn-primary px-4 p-2"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#exampleModal"
                                                                        data-bs-whatever="@mdo"
                                                                        id="btnr1"
                                                                        style={{ width: 132 }}
                                                                    >
                                                                        Apply Now &nbsp;&nbsp;
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                                                            <path
                                                                                fillRule="evenodd"
                                                                                clipRule="evenodd"
                                                                                d="M5.78009 4.45975C5.9209 4.6006 6 4.79161 6 4.99077C6 5.18993 5.9209 5.38094 5.78009 5.52179L1.53118 9.7707C1.46189 9.84244 1.37902 9.89966 1.28738 9.93902C1.19574 9.97839 1.09719 9.99911 0.997458 9.99997C0.897728 10.0008 0.798825 9.98183 0.706519 9.94407C0.614213 9.9063 0.530352 9.85053 0.45983 9.78001C0.389308 9.70949 0.333538 9.62563 0.295773 9.53332C0.258007 9.44102 0.239003 9.34211 0.23987 9.24238C0.240736 9.14266 0.261456 9.0441 0.30082 8.95246C0.340184 8.86083 0.397403 8.77795 0.46914 8.70866L4.18703 4.99077L0.469139 1.27288C0.332322 1.13122 0.256617 0.941496 0.258328 0.744563C0.260039 0.54763 0.33903 0.359248 0.478288 0.219989C0.617547 0.0807309 0.805929 0.001739 1.00286 2.80934e-05C1.1998 -0.00168282 1.38952 0.0740227 1.53118 0.210839L5.78009 4.45975Z"
                                                                                fill="white"
                                                                            />
                                                                        </svg>
                                                                    </Button>
                                                                    <br></br>

                                                                    <Link to={`/university/${data.slug}`} className="w-100">
                                                                        <Button className="btn read-morebutton mt-2" variant="outline-primary">
                                                                            Read More
                                                                        </Button>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="col-lg-4 col-md-4 col-sm-6 mt-3" key={data.uid}>
                                                <div className="card" id="icons-crd" style={{ width: '100%' }}>
                                                    <div
                                                        className="university-section pt-5 pb-5"
                                                        style={{
                                                            width: '100%',
                                                            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${data.cover_image})`,
                                                            backgroundRepeat: 'no-repeat',
                                                            backgroundSize: '100% 186%',
                                                            height: '135px',
                                                            backgroundPosition: 'center'
                                                        }}
                                                    ></div>
                                                    <div className="card-body iconerss-card">
                                                        <img src={data.logo} className="" alt="" />
                                                        <Link to={`/university/${data.slug}`}>
                                                            <h5 className="mt-4 text-transform">{data.name}</h5>
                                                        </Link>
                                                        <div>
                                                            <div className="pt-2">
                                                                <p className="para1" style={{ color: '#000' }}>
                                                                    Establish Year -
                                                                    <span className="ugc" style={{ color: '#000' }}>
                                                                        {data.estb ? data.estb : 'Not found'}
                                                                    </span>
                                                                </p>
                                                                <p className="para1" style={{ color: '#000' }}>
                                                                    Country -
                                                                    <span className="ugc" style={{ color: '#000' }}>
                                                                        {data.country ? data.country : 'Not found'}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                            <div className="anicompare d-flex justify-content-between mt-3">
                                                                <Button
                                                                    type="button"
                                                                    className="btn d-none btn-primary px-4 p-2"
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#exampleModal"
                                                                    data-bs-whatever="@mdo"
                                                                    id="btnr1"
                                                                >
                                                                    Apply Now &nbsp;&nbsp;&nbsp;
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                                                        <path
                                                                            fillRule="evenodd"
                                                                            clipRule="evenodd"
                                                                            d="M5.78009 4.45975C5.9209 4.6006 6 4.79161 6 4.99077C6 5.18993 5.9209 5.38094 5.78009 5.52179L1.53118 9.7707C1.46189 9.84244 1.37902 9.89966 1.28738 9.93902C1.19574 9.97839 1.09719 9.99911 0.997458 9.99997C0.897728 10.0008 0.798825 9.98183 0.706519 9.94407C0.614213 9.9063 0.530352 9.85053 0.45983 9.78001C0.389308 9.70949 0.333538 9.62563 0.295773 9.53332C0.258007 9.44102 0.239003 9.34211 0.23987 9.24238C0.240736 9.14266 0.261456 9.0441 0.30082 8.95246C0.340184 8.86083 0.397403 8.77795 0.46914 8.70866L4.18703 4.99077L0.469139 1.27288C0.332322 1.13122 0.256617 0.941496 0.258328 0.744563C0.260039 0.54763 0.33903 0.359248 0.478288 0.219989C0.617547 0.0807309 0.805929 0.001739 1.00286 2.80934e-05C1.1998 -0.00168282 1.38952 0.0740227 1.53118 0.210839L5.78009 4.45975Z"
                                                                            fill="white"
                                                                        />
                                                                    </svg>
                                                                </Button>
                                                                <Link to={`/university/${data.slug}`}>
                                                                    <Button className="btn" variant="outline-success">
                                                                        Read More
                                                                    </Button>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    ))
                                )}
                            </div>
                        )}
                        <div>


                            <ul className="pagination mt-4 m-2">
                                {renderPageNumbers.map(number => (
                                    <Pagination.Item
                                        key={number}
                                        id={number}
                                        active={currentPage === number}
                                        onClick={handleClick}
                                    >
                                        {number}
                                    </Pagination.Item>
                                ))}
                            </ul>

                        </div>


                        <div class="offcanvas offcanvas-top" tabindex="-1" id="offcanvasTop" aria-labelledby="offcanvasTopLabel">
                         

                            <div className='container-flex mt-5 mb-5 mobile-top offcanvs'>
                                <div className='row'>
                                    <div className='col-lg-12 col-md-12 mt-3'>
                                        <div className="card lister-card mobile_scroll">
                                            <div className="card-body">
                                                <div className='d-flex align-items-center justify-content-between'>
                                                <h6>Filters</h6>
                                                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                               
                                                    </div>
                                                
                                                <div className='d-flex justify-content-between'>
                                                   <p>Total {universities.length} Universities</p>
                                                   
                                                    <a type='button' id='clr' onClick={() => {
                                                        setCourseFilter('');
                                                        setStateFilter('');
                                                        setCityFilter('');
                                                        setStudyModeFilter('');
                                                        setCountryFilter('');
                                                    }}>Clear all</a>
                                                </div>
                                                <hr />

                                                {/* Country Filter */}
                                                <Accordion defaultActiveKey="0">
                                                    <Accordion.Item eventKey="0">
                                                        <Accordion.Header className='header-accord'>Country</Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className='pt-4'>
                                                                <form className="example">
                                                                    <input type="text" placeholder="Find Country" name="search" />
                                                                    <button type="submit">
                                                                        <img src={searchicon} className="search-icon" alt="" />
                                                                    </button>
                                                                </form>
                                                            </div>
                                                            <div className='pt-5'>
                                                                {Object.keys(filterOptions.countries).map(country => (
                                                                    <div className="form-check form-check-inline w-100" key={country}>
                                                                        <input
                                                                            className="form-check-input mt-2"
                                                                            type="radio"
                                                                            id={country}
                                                                            name="country"
                                                                            value={country}
                                                                            checked={countryFilter === country}
                                                                            onChange={(e) => setCountryFilter(e.target.value)}
                                                                        />
                                                                        <label htmlFor={country} className='form-check-label w-100 mt-2'>&nbsp; {country} ({filterOptions.countries[country]})</label>
                                                                        <br />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                                <hr />

                                                {/* State Filter */}
                                                <Accordion defaultActiveKey="0">
                                                    <Accordion.Item eventKey="0">
                                                        <Accordion.Header className='header-accord'>State</Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className='pt-4'>
                                                                <form className="example">
                                                                    <input type="text" placeholder="Find State" name="search" />
                                                                    <button type="submit">
                                                                        <img src={searchicon} className="search-icon" alt="" />
                                                                    </button>
                                                                </form>
                                                            </div>
                                                            <div className='pt-5'>
                                                                {filterOptions.states?.map(state => (
                                                                    <div className="form-check form-check-inline w-100" key={state}>
                                                                        <input
                                                                            className="form-check-input mt-2"
                                                                            type="radio"
                                                                            id={state}
                                                                            name="state"
                                                                            value={state}
                                                                            checked={stateFilter === state}
                                                                            onChange={(e) => setStateFilter(e.target.value)}
                                                                        />
                                                                        <label htmlFor={state} className='form-check-label w-100 mt-2'>&nbsp; {state}</label>
                                                                        <br />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                                <hr />

                                                {/* City Filter */}
                                                <Accordion defaultActiveKey="0">
                                                    <Accordion.Item eventKey="0">
                                                        <Accordion.Header className='header-accord'>City</Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className='pt-4'>
                                                                <form className="example">
                                                                    <input type="text" placeholder="Find City" name="search" />
                                                                    <button type="submit">
                                                                        <img src={searchicon} className="search-icon" alt="" />
                                                                    </button>
                                                                </form>
                                                            </div>
                                                            <div className='pt-5'>
                                                                {filterOptions.cities?.map(city => (
                                                                    <div className="form-check form-check-inline w-100" key={city}>
                                                                        <input
                                                                            className="form-check-input mt-2"
                                                                            type="radio"
                                                                            id={city}
                                                                            name="city"
                                                                            value={city}
                                                                            checked={cityFilter === city}
                                                                            onChange={(e) => setCityFilter(e.target.value)}
                                                                        />
                                                                        <label htmlFor={city} className='form-check-label w-100 mt-2'>&nbsp; {city}</label>
                                                                        <br />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                                <button className='btn btn-primary px-4 p-2  w-100 mt-3' data-bs-dismiss="offcanvas" aria-label="Close">Apply</button>
                                                <button className='btn  px-4 p-2 btn-danger outline-danger w-100 mt-1' onClick={() => {
                                        setCourseFilter('');
                                        setStateFilter('');
                                        setCityFilter('');
                                        setStudyModeFilter('');
                                        setCountryFilter('');
                                    }}>Reset &nbsp;<GrPowerReset /></button>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default Universitylist;
