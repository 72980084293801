import React from 'react'

function Index() {
    return (
       <>
       <div className='container test-center pt-5'>
            <h1 className='pt-5'>Thank you </h1>
     
         <br></br>
       </div>
     
       </> 
    )
}

export default Index
