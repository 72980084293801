import React, { useEffect, useRef, useState } from 'react';
import '../../../node_modules/bs-stepper/dist/css/bs-stepper.min.css';
import Stepper from 'bs-stepper';
import Frame1 from '../../../src/pages/registration-steper/registerimage/Frame1.svg';
import Frame5 from '../../../src/pages/registration-steper/registerimage/Frame6.svg';
import Frame3 from '../../../src/pages/registration-steper/registerimage/Frame4.svg';
import Frame4 from '../../../src/pages/registration-steper/registerimage/Frame5.svg';
import { SiReacthookform } from "react-icons/si";
import { Button, Form, Spinner } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import '../../../node_modules/react-phone-input-2/lib/style.css';
import { Modal } from 'bootstrap';

import { SlLike } from "react-icons/sl";
const HorizontalLinearStepper = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(''); // Manage PhoneInput state
  const [countdown, setCountdown] = useState(10);
  const formRef = useRef(null);

  const submitForm = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append('current_url', getCookie("url"));
    formData.append('campaign', 'Main-Website');
    formData.append('lead_category', 'Nayeedisha');
  
    try {
      setLoading(true);
      const response = await fetch('https://webcrmapi.nayeedisha.in/api/landingpage-leads', {
        method: 'POST',
        body: JSON.stringify(Object.fromEntries(formData)),
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      let responseData;
      try {
        responseData = await response.json();
      } catch (parseError) {
        throw new Error('Invalid JSON response from the server');
      }
  
      if (!response.ok) {
        throw new Error(JSON.stringify(responseData.error) || 'Failed to submit form');
      }
  
      setLoading(false);
      setSuccessMessage("Thank You! We have received your details. Our representative will call you shortly.");
      setErrorMessage(null);
  
      // Check if formRef.current is not null before calling reset
      if (formRef.current) {
        formRef.current.reset();
      }
      
      // Reset phone input field
      setPhoneNumber('');
  
    } catch (error) {
      let err = 'Failed to submit form';
  
      try {
        const { phone_number } = JSON.parse(error?.message);
        err = phone_number || err;
      } catch (jsonError) {
        err = error?.message || 'Failed to submit form';
      }
  
      setLoading(false);
      setSuccessMessage(null);
      setErrorMessage(err);
    }
  };

  const getCookie = (name) => {
    let value = `; ${document.cookie}`;
    let parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  };

  // Reset form and state when modal is closed
  const resetFormState = () => {
    setSuccessMessage(null);
    setErrorMessage(null);
    if (formRef.current) {
      formRef.current.reset();
    }
  };


  useEffect(() => {
    const modalElement = document.getElementById('exampleModal'); // Replace with your modal's actual ID

    if (modalElement) {
      modalElement.addEventListener('hidden.bs.modal', resetFormState);

      return () => {
        modalElement.removeEventListener('hidden.bs.modal', resetFormState);
      };
    }
  }, []);


  
  return (
    <div className='container'>
      <div className='row'>
        <div className='col-md-4 bg-light mob-hide'>
          <div className='py-3'>
            <p className='registertext'>How NayeeDisha helps you in Admission</p>
            <div className='row mt-3'>
              <div className='col-md-6'>
                <img src={Frame1} alt="img" className='w-100' />
              </div>
              <div className='col-md-6'>
                <img src={Frame5} alt="img" className='w-100' />
              </div>
            </div>
            <div className='row mt-3'>
              <div className='col-md-6'>
                <img src={Frame3} alt="img" className='w-100' />
              </div>
              <div className='col-md-6'>
                <img src={Frame4} alt="img" className='w-100' />
              </div>
            </div>
          </div>
          <p>"Our team of seasoned professionals, well-versed in the intricacies of international education"</p>
        </div>

        <div className='col-md-8'>
          <div id="stepper1" className="bs-stepper ">
            <div className="bs-stepper-content mt-3">
              {successMessage ? (
                      <div className='modalcenter'>
                 <div className="thank-you-message ">
                 <div className="thank-you-icon">
                   <SlLike size={50} color="green" className='like' />
                 </div>
                 <h3 className='modalcentertext'>Thank You</h3>
                 <p>We have received your details. Our representative will call you shortly.</p>
               
                 <button  className='aniibutton mt-2' onClick={() => window.location.reload()}>Back to Home</button>
               </div>
               </div>
              ) : (
                <>
                  <b><SiReacthookform className='circal' />&nbsp; Apply Now</b>
                  <hr />
                  {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                  <form ref={formRef} onSubmit={submitForm} id='registration'>
                    <div className='row mt-3'>
                      <div className='col-md-6'>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label>Full Name <span className="spamred">*</span></Form.Label>
                          <Form.Control type="text" name='name' placeholder="Full Name" required />
                        </Form.Group>
                      </div>
                      <div className='col-md-6'>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label>Email address <span className="spamred">*</span></Form.Label>
                          <Form.Control type="email" name='email' placeholder="name@example.com" required />
                        </Form.Group>
                      </div>
                    </div>

                    <div className='row mt-3'>
                      <div className='col-md-6'>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label>Mobile Number <span className="spamred">*</span></Form.Label>
                          <PhoneInput
                            country={'in'}
                            value={phoneNumber}
                            onChange={setPhoneNumber}
                            inputProps={{ name: 'phone_number', required: true, autoFocus: true }}
                          />
                        </Form.Group>
                      </div>
                      <div className='col-md-6'>
                      <div className="mb-3">
                        <label className="form-label">
                          Select State <span className="text-danger">*</span>
                        </label>
                        <select
                          name="state"
                          className="form-control"

                        >
                          <option value="">Please Select Preferred State</option>
                          <option value="Delhi NCR">Delhi NCR</option>
                          <option value="Uttar Pradesh">Uttar Pradesh</option>
                          <option value="Andhra Pradesh">Andhra Pradesh</option>
                          <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                          <option value="Assam">Assam</option>
                          <option value="Bihar">Bihar</option>
                          <option value="Chhattisgarh">Chhattisgarh</option>
                          <option value="Gujarat">Gujarat</option>
                          <option value="Haryana">Haryana</option>
                          <option value="Himachal Pradesh">Himachal Pradesh</option>
                          <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                          <option value="Goa">Goa</option>
                          <option value="Jharkhand">Jharkhand</option>
                          <option value="Karnataka">Karnataka</option>
                          <option value="Kerala">Kerala</option>
                          <option value="Madhya Pradesh">Madhya Pradesh</option>
                          <option value="Maharashtra">Maharashtra</option>
                          <option value="Manipur">Manipur</option>
                          <option value="Meghalaya">Meghalaya</option>
                          <option value="Mizoram">Mizoram</option>
                          <option value="Nagaland">Nagaland</option>
                          <option value="Odisha">Odisha</option>
                          <option value="Punjab">Punjab</option>
                          <option value="Rajasthan">Rajasthan</option>
                          <option value="Sikkim">Sikkim</option>
                          <option value="Tamil Nadu">Tamil Nadu</option>
                          <option value="Telangana">Telangana</option>
                          <option value="Tripura">Tripura</option>
                          <option value="Uttarakhand">Uttarakhand</option>
                          
                          <option value="West Bengal">West Bengal</option>
                          <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                          <option value="Chandigarh">Chandigarh</option>
                          <option value="Dadra and Nagar Haveli">Dadra and Nagar Haveli</option>
                          <option value="Daman and Diu">Daman and Diu</option>
                          <option value="Delhi">Delhi</option>
                          <option value="Lakshadweep">Lakshadweep</option>
                          <option value="Puducherry">Puducherry</option>
                        </select>

                      </div>

                    </div>
                    </div>

                    <div className='col-md-6'>
                    <Form.Label>Select Course<spam className="spamred">*</spam></Form.Label>
                    <select class="form-select" aria-label="Default select example" name='course_interested'>
                      <option selected>Please Select Preferred Stream</option>
                      <option value="MBBS Abroad">MBBS Abroad</option>
                      <option value="MBBS India">MBBS India</option>                     
                      <option value="Engineering">Engineering</option>
                      <option value="Management">Management</option>
                      <option value="BDS">BDS</option>
                      <option value="BAMS">BAMS</option>
                      <option value="Hotel Management">Hotel Management</option>
                      <option value="Law">Law</option>
                      <option value="Paramedical">Paramedical</option>


                    

                    </select>
                  </div>
<br></br>
                    <div className="text-center">
                    <button type="submit" className="btn btn-primary btn-accent aniibutton mt-3" disabled={loading}>
                        
                        {loading ? (
                          <>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            <span className="sr-only">Loading...</span>
                          </>
                        ) : "Submit"}
                      </button>

                    </div>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HorizontalLinearStepper;