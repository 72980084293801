
import { React, useEffect, useState, Component, Fragment } from "react";
import aboutusbanner from '../img/aboutusbanner.png';
import { Bs0CircleFill, Bs1CircleFill, Bs2CircleFill, Bs3CircleFill, Bs4CircleFill, Bs5CircleFill, Bs6CircleFill, Bs7CircleFill, Bs8CircleFill, Bs9CircleFill } from "react-icons/bs";
import Admissionimg from '../../img/admission.svg'
import { BsGlobe } from "react-icons/bs";
import OwlCarousel from "react-owl-carousel";
import "../../../node_modules/owl.carousel/dist/assets/owl.carousel.css";
import "../../../node_modules/owl.carousel/dist/assets/owl.theme.default.css";
import { Spinner } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import lister1 from '../img/lister1.jpg';
import iconlister from '../img/iconlister.jpg';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
const UniversityShanghaiChina = () => {
  const options = {
    loop: true,
    margin: 10,
    items: 1,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
        nav: true,

      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };
  const [universities, setUniversities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetch('https://webcrmapi.nayeedisha.in/api/university-details')
      .then((response) => response.json())
      .then((data) => {
        // Assuming 'results' contains the array of universities
        setUniversities(data);
        setIsLoading(false);
   
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('Error fetching data:', error);
      });
  }, []);
  return (
    <>



      {/* section1 */}

      <div className='abt-section pt-5 pb-5'>


        <div className='container'>

          <h3>MBBS in Peking University Health Science Center, China</h3>

          <div className='bds'></div>
          <div id='brdrs'></div>

          {/* <p>It is a long established fact that a reader.</p> */}


        </div>


      </div>




      <div className='container abt-2 mt-5 mb-5'>


        <p className='mb-4'>Welcome to the page dedicated to Peking University Health Science Center (PKUHSC), a renowned institution at the forefront of medical education and research. Situated in the vibrant city of Beijing, China, PKUHSC is a prestigious hub of academic excellence and innovation in the field of health sciences. With a rich history spanning decades, PKUHSC stands as a beacon of transformative healthcare education, dedicated to producing exceptional medical professionals and advancing cutting-edge research. Explore this page to uncover the remarkable journey and exceptional offerings of Peking University Health Science Center.</p>

        <hr></hr>
        <div className='py-3 rounded'>

          <div className='container mt-3'>
            <h3 className='border_before'>Advantages of MBBS in Peking University Health Science Center, China</h3>
            <div className='row'>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height  p-3 rounded'>
                  <b className='f-19'>Global Recognition</b>
                  <p className='f-17'>Acclaimed Degrees Opening the International Doors and Precious Chances</p>
                </div>

              </div>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>Affordable Education</b>
                  <p className='f-17'>Cost-Effective MBBS Programs without Compromising Quality and Rigour</p>
                </div>

              </div>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>Experienced Faculty</b>
                  <p className='f-17'>Renowned Professors Ensuring Comprehensive Medical Education Is Provided</p>
                </div>

              </div>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>Cultural Diversity</b>
                  <p className='f-17'>Exposure to Multicultural Environment Enhancing Global Perspective and Exposure</p>
                </div>

              </div>
            </div>
          </div>

          <div className='container mb-4 mt-3'>
            <div className='row'>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>



                  <b className='f-19'>Advanced Infrastructure</b>
                  <p className='f-17'>State-of-the-Art Facilities Are Provided Cutting-Edge Learning Opportunities</p>
                </div>

              </div>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>High-Quality Teaching</b>
                  <p className='f-17'>Rigorous Curriculum and Practical Training for Competent Medical Professionals</p>
                </div>

              </div>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>English-Medium</b>
                  <p className='f-17'>Language Barrier-Free Education Facilitating Easy Learning and Communication</p>
                </div>

              </div>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>Career Opportunities</b>
                  <p className='f-17'>Seamless Pathway to Explore Employment Options in Russia and Beyond.</p>
                </div>

              </div>
            </div>
          </div>
        </div>



        <hr></hr>




      </div>
      <div className='container'>
        <h3 className='border_before'>Facts about Peking University Health Science Center, China</h3>
        <div className='row'>
          <div className='col-md-12'>
            <ul className='list-none mt-2'>
              <li className='mt-1 '><Bs1CircleFill />&nbsp; Established Legacy: Peking University Health Science Center (PKUHSC) was founded in 1912, making it one of the oldest and most prestigious health science institutions in China.</li>
              <li className='mt-2 '> <Bs2CircleFill /> &nbsp; Renowned Reputation: PKUHSC consistently ranks among the top medical schools in China and has garnered international recognition for its academic excellence and contributions to healthcare research.</li>
              <li className='mt-2 '> <Bs3CircleFill />&nbsp; Broad Academic Scope: The center offers a wide range of programs and disciplines, including medicine, dentistry, nursing, public health, and biomedical sciences, catering to the diverse interests and career aspirations of students.</li>
              <li className='mt-2 '> <Bs4CircleFill /> &nbsp; Outstanding Faculty: PKUHSC boasts a team of highly qualified and accomplished faculty members who are experts in their respective fields, providing students with exceptional mentorship and guidance.</li>
              <li className='mt-2 '> <Bs5CircleFill /> &nbsp; State-of-the-Art Facilities: The center is equipped with modern facilities, laboratories, research centers, and teaching hospitals that offer students hands-on learning experiences and access to cutting-edge medical technologies.</li>
              <li className='mt-2 '> <Bs6CircleFill /> &nbsp; Research Powerhouse: PKUHSC is renowned for its research prowess, actively engaging in groundbreaking studies in areas such as genetics, oncology, infectious diseases, neuroscience, and public health, to name a few.</li>
              <li className='mt-2 '><Bs7CircleFill /> &nbsp; Collaborative Partnerships: The center has established collaborations with leading international institutions, fostering global academic exchanges, joint research projects, and student exchange programs.</li>
              <li className='mt-2 '><Bs8CircleFill /> &nbsp; Commitment to Global Health: PKUHSC actively contributes to global health initiatives and endeavors, working towards improving healthcare delivery, promoting disease prevention, and addressing global health challenges.</li>
              <li className='mt-2 '><Bs9CircleFill /> &nbsp; Multidisciplinary Approach: PKUHSC encourages interdisciplinary collaboration, fostering a comprehensive approach to healthcare education and research that integrates diverse perspectives and expertise.</li>

            </ul>
          </div>

        </div>
      </div>
      <hr></hr>
      <div className='container'>
        <h3 className='border_before'>Admission Process and Documents Needed</h3>
        <div className='row'>
          <div className='col-md-12'>

          </div>

        </div>
      </div>

      <div className='container'>

        <img src={Admissionimg} alt="img" className='w-100 mt-3' />
      </div>

      <br></br>
  
    </>
  );
};

export default UniversityShanghaiChina;





