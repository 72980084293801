import { React, useEffect, useState, Component, Fragment } from "react";
import aboutusbanner from '../img/aboutusbanner.png';
import { Bs1CircleFill, Bs2CircleFill, Bs3CircleFill, Bs4CircleFill, Bs5CircleFill, Bs6CircleFill,Bs7CircleFill } from "react-icons/bs";
import Admissionimg from '../../img/admission.svg'
import { BsGlobe } from "react-icons/bs";
import OwlCarousel from "react-owl-carousel";
import "../../../node_modules/owl.carousel/dist/assets/owl.carousel.css";
import "../../../node_modules/owl.carousel/dist/assets/owl.theme.default.css";
import { Spinner } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import lister1 from '../img/lister1.jpg';
import iconlister from '../img/iconlister.jpg';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import apiUrl from '../../setting/apiConfig';
const Mbbsinnepal = () => {
    const options = {
        loop: true,
        margin: 10,
        items: 1,
        autoplay: true,
        responsive: {
           0: {
              items: 1,
              nav:true,
              
           },
           600: {
              items: 3,
           },
           1000: {
              items: 4,
           },
        },
     };
    const [universities, setUniversities] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
       fetch('https://webcrmapi.nayeedisha.in/api/university-details')
          .then((response) => response.json())
          .then((data) => {
             // Assuming 'results' contains the array of universities
             setUniversities(data);
             setIsLoading(false);
  
          })
          .catch((error) => {
             setIsLoading(false);
             console.error('Error fetching data:', error);
          });
    }, []);


    const [russiaUniversities, setRussiaUniversities] = useState([]);
    const [nepalUniversities, setNepalUniversities] = useState([]);


      // show university according country
  useEffect(() => {
   const fetchData = async () => {
       try {
           setIsLoading(true);

           // Check if data is available in localStorage
           const cachedData = localStorage.getItem('university-details');

           if (cachedData) {
               const universityData = JSON.parse(cachedData); // Use cached data

               // Filter universities for Russia and Nepal
               const russiaFiltered = universityData.filter(university => university.country === 'Russia');
               const nepalFiltered = universityData.filter(university => university.country === 'Nepal');

               // Update state with filtered data
               setRussiaUniversities(russiaFiltered);
               setNepalUniversities(nepalFiltered);

               setIsLoading(false);
           } else {
               const universityResponse = await fetch(apiUrl + 'university-details');
               const universityData = await universityResponse.json();

               // Store fetched data in localStorage
               localStorage.setItem('university-details', JSON.stringify(universityData));

               // Filter universities for Russia and Nepal
               const russiaFiltered = universityData.filter(university => university.country === 'Russia');
               const nepalFiltered = universityData.filter(university => university.country === 'Nepal');

               // Update state with filtered data
               setRussiaUniversities(russiaFiltered);
               setNepalUniversities(nepalFiltered);

               setIsLoading(false);
           }
       } catch (error) {
           setIsLoading(false);
           console.error('Error fetching data:', error);
       }
   };

   fetchData();
}, []);
    return (
        <>



            {/* section1 */}

            <div className='abt-section pt-5 pb-5'>


                <div className='container'>

                    <h3>MBBS in Nepal</h3>
                    <Breadcrumb>
      <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
      <Breadcrumb.Item active>
        MBBS In Nepal
      </Breadcrumb.Item>
    
    </Breadcrumb>
                    <div className='bds'></div>
                    <div id='brdrs'></div>

                    {/* <p>It is a long established fact that a reader.</p> */}


                </div>


            </div>




            <div className='container abt-2 mt-5 mb-5'>


                <p className='mb-4'>Bashkir State Medical University (BSMU), located in Ufa, Russia, is a prestigious institution renowned for its excellence in medical education and research. Established in 1932, the university has a rich history of producing highly skilled medical professionals who contribute significantly to the healthcare sector both within Russia and globally. BSMU offers a wide range of undergraduate, postgraduate, and doctoral programs in various medical disciplines, including general medicine, dentistry, pharmacy, nursing, and preventive medicine.</p>
                <p>BSMU boasts state-of-the-art facilities and a dedicated faculty comprising experienced professors and medical practitioners. The university places a strong emphasis on practical training, providing students with ample opportunities to gain hands-on experience through clinical rotations and internships in renowned medical institutions. The curriculum is comprehensive, combining theoretical knowledge with practical skills, ensuring that graduates are well-prepared to meet the challenges of the medical field. BSMU also actively engages in research activities, promoting innovation and advancement in medical sciences.</p>

                <hr></hr>
            </div>
            <div className='container'>
                <h3 className='border_before'>Facts about Bashkir State Medical University, Russia</h3>
                <div className='row'>
                    <div className='col-md-12'>
                        <ul className='list-none mt-2'>
                            <li className='mt-1'><Bs1CircleFill />&nbsp; BSMU is located in Ufa, the capital city of the Republic of Bashkortostan in Russia.</li>
                            <li className='mt-2 '> <Bs2CircleFill /> &nbsp; It was established in 1932 and has a long-standing history in medical education and research.</li>
                            <li className='mt-2 '> <Bs3CircleFill />&nbsp; BSMU is one of the leading medical universities in Russia and holds a high reputation for its academic programs.</li>
                            <li className='mt-2 '> <Bs4CircleFill /> &nbsp;The university offers undergraduate, postgraduate, and doctoral programs in various medical disciplines.</li>
                            <li className='mt-2 '> <Bs5CircleFill /> &nbsp;BSMU has a modern campus equipped with advanced facilities and laboratories for practical training.</li>
                            <li className='mt-2 '> <Bs6CircleFill /> &nbsp;The faculty at BSMU consists of experienced professors and medical professionals who provide quality education and guidance to students.</li>
                            <li  className='mt-2 '><Bs7CircleFill /> &nbsp;The university places a strong emphasis on practical training, offering clinical rotations and internships in reputed medical institutions.</li>
                        </ul>
                    </div>

                </div>
            </div>
<hr></hr>

<br></br>
<div className="container">
    <div className="text-center">
    <h3 className='border_before'>Nepal Colleges</h3>
    </div>

 <div className="row">
 {nepalUniversities.map((university) => (
  <div key={university.uid} className="col-md-4 mt-4">
    <div className="card" id="icons-crd" style={{ width: '100%' }}>
      <img 
        src={university.cover_image}
        className="card-img-top" 
        alt="NayeeDisha" 
      />
      <div className="card-body iconerss-card">
        <img 
          src={university.logo}
          alt="NayeeDisha" 
        />
        <Link to={`/university/${university.slug}`}>
          <h5 className="mt-1 text-transform">{university.name}</h5>
        </Link>
        <div>
          <div className="pt-2 college_part">
            <p className="para1" style={{ color: '#000' }}>
              Establish Year - 
              <span className="ugc" style={{ color: '#000' }}> 
                {university.estb || '1899'} 
              </span>
            </p> 
            |
            <p className="para1">
              Country - 
              <span className="ugc" style={{ color: '#000' }}> 
                {university.country || 'Russia'} 
              </span>
            </p>
          </div>
          <div className="d-flex justify-content-between mt-3">
           
            <Link to={`/university/${university.slug}`}>
              <Button className="btn" variant="outline-success">Read More</Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
))}
           </div>          
         </div>
         <br></br>
        </>
    );
};

export default Mbbsinnepal;

