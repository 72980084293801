import { React, useState, useEffect } from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import { IoMdInformationCircle } from "react-icons/io";
import Educationlogo from "../img/educationlogo.png";
import {
  FaHome,
  FaGem,
  FaList,
  FaSpellCheck,
  FaRegLaughWink,
  FaHeart,
  FaRegSun,
  FaRegUserCircle,
  FaHeadphonesAlt,
  FaProductHunt,
  FaBuilding
} from "react-icons/fa";
import { MdMiscellaneousServices } from "react-icons/md";
import { IoMdContact } from "react-icons/io";
import { LuRussianRuble } from "react-icons/lu";
import { FcAbout } from "react-icons/fc";
import { FaFlag } from "react-icons/fa6";
import { LiaUniversitySolid } from "react-icons/lia";
import { ImCross } from "react-icons/im";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { GrBlog } from "react-icons/gr";
import searchicon from "../components/search-icon.png";
import nayeedishalogosfin from "../img/nayeedishalogos-fin.svg";
import apiUrl from '../setting/apiConfig'
import ReactSearchBox from "react-search-box";


const Aside = ({ collapsed, toggled, handleToggleSidebar, handleToggle }) => {
  const [universities, setUniversities] = useState([]);
  const [isloading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const universityResponse = await fetch(apiUrl + 'university-details');
        const universityData = await universityResponse.json();

        // Filter universities for Russia
        const russiaUniversities = universityData.filter(university => university.country === 'Russia');

        setUniversities(russiaUniversities);
       
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  const searchData = [
    {
      key: "ani",
      value: "Aniket",
      url: "https://word.com/"
    },
    {
      key: "jane",
      value: "Jane Doe",
      url: "https://facebook.com/"
    },
    {
      key: "mary",
      value: "Mary Phillips",
      url: "https://example.com/mary"
    },
    {
      key: "robert",
      value: "Robert",
      url: "https://example.com/robert"
    },
    {
      key: "karius",
      value: "Karius",
      url: "https://example.com/karius"
    }
  ];
  const onSelect = (record) => {
    window.location.href = record.url;
  
  };

  const renderOption = (record, index) => {
    return (
      <div key={index} onClick={() => onSelect(record)}>
        <span>{record.value}</span>
        <span style={{ marginLeft: '5px', color: '#999' }}>{record.url}</span>
      </div>
    );
  };

  return (

    <ProSidebar
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader>
        <div
          style={{
            padding: "24px",
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: 14,
            letterSpacing: "1px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <img src={nayeedishalogosfin} alt="logo" className="logomibile-image"></img>
        </div>



        {/*  */}
        <div className='p-2'>

          <ReactSearchBox
            placeholder="Search for MBBS, Btech or More"
            data={searchData}
            onSelect={onSelect}
            renderOption={renderOption}
          />



        </div>


      </SidebarHeader>

      <SidebarContent>

        <Menu iconShape="">
          <Link onClick={() => handleToggle()} to={"/"}> <MenuItem icon={<FaHome />} > Home</MenuItem></Link>
          <Link onClick={() => handleToggle()} to={"/about-us"}> <MenuItem icon={<IoMdInformationCircle />}> About Nayee Disha</MenuItem></Link>
          <Link onClick={() => handleToggle()} to={"/services"}> <MenuItem icon={<MdMiscellaneousServices />} > Our Services</MenuItem></Link>
          <SubMenu
            suffix={<span className="badge yellow"></span>}
            title={"Study In India"}
            icon={<LiaUniversitySolid />}
          >

            <Link onClick={() => handleToggle()} to={"/mbbs-india"}>
              <MenuItem>{" MBBS in India"} </MenuItem>
            </Link>
            <Link onClick={() => handleToggle()} to={"/engineering-india"}>
              <MenuItem>{"Engineering in India"}</MenuItem>
            </Link>


          </SubMenu>

          <SubMenu
            suffix={<span className="badge yellow"></span>}
            title={"Study Abroad"}
            icon={<FaFlag />}
          >

            <SubMenu suffix={<span className="badge yellow"></span>}
              title={"Russia"}
              icon={<LuRussianRuble />}
            >
              {universities.map(university => (


                <Link key={university.uid} onClick={() => handleToggle()} to={`/university/${university.slug}`}>
                  <MenuItem>{university.name}  </MenuItem>
                </Link>
              ))}

            </SubMenu>
          </SubMenu>
          <Link onClick={() => handleToggle()} to={"/blogs"}> <MenuItem icon={<GrBlog />} >Blogs</MenuItem></Link>

          <Link onClick={() => handleToggle()} to={"/contact-us"}> <MenuItem icon={<IoMdContact />} > Contact Us</MenuItem></Link>

        </Menu>
      </SidebarContent>

      <SidebarFooter>
        <div
          className="sidebar-btn-wrapper"
          style={{
            padding: "20px 24px",
          }}
        >
          <Link to="/contact-us" className="sidebar-btn" rel="noopener noreferrer">
            <span
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {/* <Button Button variant="contained" color="secondary">
                <FaHeadphonesAlt className="svgwhite" /> &nbsp; Get Support
              </Button> */}
            </span>
          </Link>
        </div>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default Aside;
